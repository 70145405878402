/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Title:   Laapp - Sass & Startup HTML Template - Main CSS file
 * Author:  http://themeforest.net/user/5studios | www.5studios.net
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

[ TABLE OF CONTENTS ]

1. CUSTOM BOOTSTRAP COMPILATION
2. RESET STYLES
3. ANIMATIONS
4. FORMS
    3.1 - Form
    3.2 - Buttons
5. UTILITIES
    4.1 - Background
    4.2 - Border
    4.3 - Devices
    4.4 - Lists
    4.5 - Misc
    4.6 - Overlay
    4.7 - Position
    4.8 - Responsive
    4.9 - Spacing
    4.10 - Text
    4.11 - Type
6. COMPONENTS

5. GENERAL CONTENT STYLES
    5.1 - Page Loader
    5.2 - Mockup
    5.3 - Icons
6. SECTIONS
    6.1 - General Styles
    6.2 - Testimonials
    6.3 - Footer
7. PRICING
    7.1 - General styles
    7.2 - Pricing heading
8. PAGE HEADING
9. CUSTOM
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* PANTONE 16-1546 Living Coral
 * Theme inspired by the following entry
 * https://design.tutsplus.com/articles/pantone-color-of-the-year-2019--cms-32338
 *
 * Taking the palette from the exploration tab according to Pantone site
 * https://www.pantone.com/color-intelligence/color-of-the-year/color-of-the-year-2019-palette-exploration
 *
 * Feel free to modify the palette according to your needs
 */
/* ==========================================================================
    [1. CUSTOM BOOTSTRAP COMPILATION]
========================================================================== */
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #2095f2;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #f44236;
  --orange: #fd7e14;
  --yellow: #ff9700;
  --green: #37bd74;
  --teal: #20c997;
  --cyan: #11cdef;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #ff6f61;
  --secondary: #fc9e21;
  --success: #37bd74;
  --info: #11cdef;
  --warning: #dc793e;
  --danger: #f44236;
  --light: #edf4f8;
  --dark: #181715;
  --alternate: #007cb7;
  --contrast: #fff;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #27333e;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: 900; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007cb7;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #00486b;
    text-decoration: none; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }
  @media (max-width: 1200px) {
    legend {
      font-size: calc(1.275rem + 0.3vw) ; } }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }
  @media (max-width: 1200px) {
    h1, .h1 {
      font-size: calc(1.375rem + 1.5vw) ; } }

h2, .h2 {
  font-size: 2rem; }
  @media (max-width: 1200px) {
    h2, .h2 {
      font-size: calc(1.325rem + 0.9vw) ; } }

h3, .h3 {
  font-size: 1.75rem; }
  @media (max-width: 1200px) {
    h3, .h3 {
      font-size: calc(1.3rem + 0.6vw) ; } }

h4, .h4 {
  font-size: 1.5rem; }
  @media (max-width: 1200px) {
    h4, .h4 {
      font-size: calc(1.275rem + 0.3vw) ; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-1 {
      font-size: calc(1.725rem + 5.7vw) ; } }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-2 {
      font-size: calc(1.675rem + 5.1vw) ; } }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-3 {
      font-size: calc(1.575rem + 3.9vw) ; } }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-4 {
      font-size: calc(1.475rem + 2.7vw) ; } }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 0.875em;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #27333e; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #edf4f8; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #edf4f8; }
  .table tbody + tbody {
    border-top: 2px solid #edf4f8; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #edf4f8; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #edf4f8; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #27333e;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #ffd7d3; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #ffb4ad; }

.table-hover .table-primary:hover {
  background-color: #ffc0ba; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #ffc0ba; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #fee4c1; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #fdcd8c; }

.table-hover .table-secondary:hover {
  background-color: #fed9a8; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #fed9a8; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c7edd8; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #97ddb7; }

.table-hover .table-success:hover {
  background-color: #b4e7cb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b4e7cb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bcf1fb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #83e5f7; }

.table-hover .table-info:hover {
  background-color: #a4ecfa; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #a4ecfa; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f5d9c9; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #edb99b; }

.table-hover .table-warning:hover {
  background-color: #f1cab3; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #f1cab3; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fccac7; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f99d96; }

.table-hover .table-danger:hover {
  background-color: #fbb3af; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #fbb3af; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fafcfd; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f6f9fb; }

.table-hover .table-light:hover {
  background-color: #e8f1f6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #e8f1f6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bebebd; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #878685; }

.table-hover .table-dark:hover {
  background-color: #b1b1b0; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b1b1b0; }

.table-alternate,
.table-alternate > th,
.table-alternate > td {
  background-color: #b8daeb; }

.table-alternate th,
.table-alternate td,
.table-alternate thead th,
.table-alternate tbody + tbody {
  border-color: #7abbda; }

.table-hover .table-alternate:hover {
  background-color: #a4d0e5; }
  .table-hover .table-alternate:hover > td,
  .table-hover .table-alternate:hover > th {
    background-color: #a4d0e5; }

.table-contrast,
.table-contrast > th,
.table-contrast > td {
  background-color: white; }

.table-contrast th,
.table-contrast td,
.table-contrast thead th,
.table-contrast tbody + tbody {
  border-color: white; }

.table-hover .table-contrast:hover {
  background-color: #f2f2f2; }
  .table-hover .table-contrast:hover > td,
  .table-hover .table-contrast:hover > th {
    background-color: #f2f2f2; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #edf4f8; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #595752;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d3d4d5;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #4a4843;
    background-color: #edf4f8;
    border-color: #b5bab6;
    outline: 0;
    box-shadow: none; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #595752; }

select.form-control:focus::-ms-value {
  color: #595752;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #27333e;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #37bd74; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(55, 189, 116, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #37bd74;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2337bd74' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #37bd74;
    box-shadow: 0 0 0 0.2rem rgba(55, 189, 116, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #37bd74;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2337bd74' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #37bd74;
    box-shadow: 0 0 0 0.2rem rgba(55, 189, 116, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #37bd74; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #37bd74; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #37bd74; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #58cf8e;
  background-color: #58cf8e; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(55, 189, 116, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #37bd74; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #37bd74; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #37bd74;
  box-shadow: 0 0 0 0.2rem rgba(55, 189, 116, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #f44236; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(244, 66, 54, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #f44236;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f44236' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f44236' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #f44236;
    box-shadow: 0 0 0 0.2rem rgba(244, 66, 54, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #f44236;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f44236' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f44236' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #f44236;
    box-shadow: 0 0 0 0.2rem rgba(244, 66, 54, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #f44236; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #f44236; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #f44236; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f76f66;
  background-color: #f76f66; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(244, 66, 54, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f44236; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #f44236; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #f44236;
  box-shadow: 0 0 0 0.2rem rgba(244, 66, 54, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #27333e;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 0.1rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #27333e;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: none; }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #212529;
  background-color: #ff6f61;
  border-color: #ff6f61; }
  .btn-primary:hover {
    color: #fff;
    background-color: #ff4c3b;
    border-color: #ff412e; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #ff4c3b;
    border-color: #ff412e;
    box-shadow: 0 0 0 0.2rem rgba(222, 100, 89, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #212529;
    background-color: #ff6f61;
    border-color: #ff6f61; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #ff412e;
    border-color: #ff3521; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 100, 89, 0.5); }

.btn-secondary {
  color: #212529;
  background-color: #fc9e21;
  border-color: #fc9e21; }
  .btn-secondary:hover {
    color: #212529;
    background-color: #f38c03;
    border-color: #e78503; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #212529;
    background-color: #f38c03;
    border-color: #e78503;
    box-shadow: 0 0 0 0.2rem rgba(219, 140, 34, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #fc9e21;
    border-color: #fc9e21; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #e78503;
    border-color: #da7e03; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(219, 140, 34, 0.5); }

.btn-success {
  color: #fff;
  background-color: #37bd74;
  border-color: #37bd74; }
  .btn-success:hover {
    color: #fff;
    background-color: #2e9f62;
    border-color: #2c955c; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #2e9f62;
    border-color: #2c955c;
    box-shadow: 0 0 0 0.2rem rgba(85, 199, 137, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #37bd74;
    border-color: #37bd74; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #2c955c;
    border-color: #298c56; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(85, 199, 137, 0.5); }

.btn-info {
  color: #212529;
  background-color: #11cdef;
  border-color: #11cdef; }
  .btn-info:hover {
    color: #fff;
    background-color: #0eafcc;
    border-color: #0da5c0; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #0eafcc;
    border-color: #0da5c0;
    box-shadow: 0 0 0 0.2rem rgba(19, 180, 209, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #212529;
    background-color: #11cdef;
    border-color: #11cdef; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #0da5c0;
    border-color: #0c9ab4; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(19, 180, 209, 0.5); }

.btn-warning {
  color: #fff;
  background-color: #dc793e;
  border-color: #dc793e; }
  .btn-warning:hover {
    color: #fff;
    background-color: #ce6425;
    border-color: #c45f23; }
  .btn-warning:focus, .btn-warning.focus {
    color: #fff;
    background-color: #ce6425;
    border-color: #c45f23;
    box-shadow: 0 0 0 0.2rem rgba(225, 141, 91, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #fff;
    background-color: #dc793e;
    border-color: #dc793e; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #c45f23;
    border-color: #b95a22; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 141, 91, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #f44236;
  border-color: #f44236; }
  .btn-danger:hover {
    color: #fff;
    background-color: #f22012;
    border-color: #ea1b0d; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #f22012;
    border-color: #ea1b0d;
    box-shadow: 0 0 0 0.2rem rgba(246, 94, 84, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #f44236;
    border-color: #f44236; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1b0d;
    border-color: #de190c; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(246, 94, 84, 0.5); }

.btn-light {
  color: #212529;
  background-color: #edf4f8;
  border-color: #edf4f8; }
  .btn-light:hover {
    color: #212529;
    background-color: #d1e3ed;
    border-color: #c8deea; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #d1e3ed;
    border-color: #c8deea;
    box-shadow: 0 0 0 0.2rem rgba(206, 213, 217, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #edf4f8;
    border-color: #edf4f8; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c8deea;
    border-color: #bfd8e6; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(206, 213, 217, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #181715;
  border-color: #181715; }
  .btn-dark:hover {
    color: #fff;
    background-color: #040303;
    border-color: black; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #040303;
    border-color: black;
    box-shadow: 0 0 0 0.2rem rgba(59, 58, 56, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #181715;
    border-color: #181715; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(59, 58, 56, 0.5); }

.btn-alternate {
  color: #fff;
  background-color: #007cb7;
  border-color: #007cb7; }
  .btn-alternate:hover {
    color: #fff;
    background-color: #006291;
    border-color: #005984; }
  .btn-alternate:focus, .btn-alternate.focus {
    color: #fff;
    background-color: #006291;
    border-color: #005984;
    box-shadow: 0 0 0 0.2rem rgba(38, 144, 194, 0.5); }
  .btn-alternate.disabled, .btn-alternate:disabled {
    color: #fff;
    background-color: #007cb7;
    border-color: #007cb7; }
  .btn-alternate:not(:disabled):not(.disabled):active, .btn-alternate:not(:disabled):not(.disabled).active,
  .show > .btn-alternate.dropdown-toggle {
    color: #fff;
    background-color: #005984;
    border-color: #005177; }
    .btn-alternate:not(:disabled):not(.disabled):active:focus, .btn-alternate:not(:disabled):not(.disabled).active:focus,
    .show > .btn-alternate.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 144, 194, 0.5); }

.btn-contrast {
  color: #212529;
  background-color: #fff;
  border-color: #fff; }
  .btn-contrast:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-contrast:focus, .btn-contrast.focus {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-contrast.disabled, .btn-contrast:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-contrast:not(:disabled):not(.disabled):active, .btn-contrast:not(:disabled):not(.disabled).active,
  .show > .btn-contrast.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-contrast:not(:disabled):not(.disabled):active:focus, .btn-contrast:not(:disabled):not(.disabled).active:focus,
    .show > .btn-contrast.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

.btn-outline-primary {
  color: #ff6f61;
  border-color: #ff6f61; }
  .btn-outline-primary:hover {
    color: #212529;
    background-color: #ff6f61;
    border-color: #ff6f61; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 111, 97, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #ff6f61;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #ff6f61;
    border-color: #ff6f61; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 111, 97, 0.5); }

.btn-outline-secondary {
  color: #fc9e21;
  border-color: #fc9e21; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #fc9e21;
    border-color: #fc9e21; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(252, 158, 33, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #fc9e21;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #fc9e21;
    border-color: #fc9e21; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(252, 158, 33, 0.5); }

.btn-outline-success {
  color: #37bd74;
  border-color: #37bd74; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #37bd74;
    border-color: #37bd74; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(55, 189, 116, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #37bd74;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #37bd74;
    border-color: #37bd74; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(55, 189, 116, 0.5); }

.btn-outline-info {
  color: #11cdef;
  border-color: #11cdef; }
  .btn-outline-info:hover {
    color: #212529;
    background-color: #11cdef;
    border-color: #11cdef; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(17, 205, 239, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #11cdef;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #212529;
    background-color: #11cdef;
    border-color: #11cdef; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(17, 205, 239, 0.5); }

.btn-outline-warning {
  color: #dc793e;
  border-color: #dc793e; }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #dc793e;
    border-color: #dc793e; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 121, 62, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #dc793e;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #dc793e;
    border-color: #dc793e; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 121, 62, 0.5); }

.btn-outline-danger {
  color: #f44236;
  border-color: #f44236; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #f44236;
    border-color: #f44236; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 66, 54, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #f44236;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44236;
    border-color: #f44236; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(244, 66, 54, 0.5); }

.btn-outline-light {
  color: #edf4f8;
  border-color: #edf4f8; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #edf4f8;
    border-color: #edf4f8; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(237, 244, 248, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #edf4f8;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #edf4f8;
    border-color: #edf4f8; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(237, 244, 248, 0.5); }

.btn-outline-dark {
  color: #181715;
  border-color: #181715; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #181715;
    border-color: #181715; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(24, 23, 21, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #181715;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #181715;
    border-color: #181715; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(24, 23, 21, 0.5); }

.btn-outline-alternate {
  color: #007cb7;
  border-color: #007cb7; }
  .btn-outline-alternate:hover {
    color: #fff;
    background-color: #007cb7;
    border-color: #007cb7; }
  .btn-outline-alternate:focus, .btn-outline-alternate.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 124, 183, 0.5); }
  .btn-outline-alternate.disabled, .btn-outline-alternate:disabled {
    color: #007cb7;
    background-color: transparent; }
  .btn-outline-alternate:not(:disabled):not(.disabled):active, .btn-outline-alternate:not(:disabled):not(.disabled).active,
  .show > .btn-outline-alternate.dropdown-toggle {
    color: #fff;
    background-color: #007cb7;
    border-color: #007cb7; }
    .btn-outline-alternate:not(:disabled):not(.disabled):active:focus, .btn-outline-alternate:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-alternate.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 124, 183, 0.5); }

.btn-outline-contrast {
  color: #fff;
  border-color: #fff; }
  .btn-outline-contrast:hover {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-contrast:focus, .btn-outline-contrast.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-contrast.disabled, .btn-outline-contrast:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-contrast:not(:disabled):not(.disabled):active, .btn-outline-contrast:not(:disabled):not(.disabled).active,
  .show > .btn-outline-contrast.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-contrast:not(:disabled):not(.disabled):active:focus, .btn-outline-contrast:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-contrast.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007cb7;
  text-decoration: none; }
  .btn-link:hover {
    color: #00486b;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.width {
        transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #27333e;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #ff6f61; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.075rem;
  padding-left: 0.075rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #595752;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #d3d4d5;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #ff6f61;
    background-color: #ff6f61; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none; }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #b5bab6; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: white;
    border-color: white; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: 1px solid #adb5bd; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #ff6f61;
  background-color: #ff6f61; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(255, 111, 97, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(255, 111, 97, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(255, 111, 97, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(255, 111, 97, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #595752;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #d3d4d5;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #b5bab6;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 111, 97, 0.25); }
    .custom-select:focus::-ms-value {
      color: #595752;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #595752; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #b5bab6;
    box-shadow: none; }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #595752;
  background-color: #fff;
  border: 1px solid #d3d4d5;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #595752;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, none; }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, none; }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #ff6f61;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: white; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #ff6f61;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: white; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #ff6f61;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: white; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ff6f61; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007cb7;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #00486b;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #ff6f61;
  border-color: #ff6f61; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #212529;
  background-color: #ff6f61; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #212529;
    background-color: #ff412e; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 111, 97, 0.5); }

.badge-secondary {
  color: #212529;
  background-color: #fc9e21; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #212529;
    background-color: #e78503; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(252, 158, 33, 0.5); }

.badge-success {
  color: #fff;
  background-color: #37bd74; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #2c955c; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(55, 189, 116, 0.5); }

.badge-info {
  color: #212529;
  background-color: #11cdef; }
  a.badge-info:hover, a.badge-info:focus {
    color: #212529;
    background-color: #0da5c0; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(17, 205, 239, 0.5); }

.badge-warning {
  color: #fff;
  background-color: #dc793e; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #fff;
    background-color: #c45f23; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 121, 62, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #f44236; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #ea1b0d; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 66, 54, 0.5); }

.badge-light {
  color: #212529;
  background-color: #edf4f8; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #c8deea; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(237, 244, 248, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #181715; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: black; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(24, 23, 21, 0.5); }

.badge-alternate {
  color: #fff;
  background-color: #007cb7; }
  a.badge-alternate:hover, a.badge-alternate:focus {
    color: #fff;
    background-color: #005984; }
  a.badge-alternate:focus, a.badge-alternate.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 124, 183, 0.5); }

.badge-contrast {
  color: #212529;
  background-color: #fff; }
  a.badge-contrast:hover, a.badge-contrast:focus {
    color: #212529;
    background-color: #e6e6e6; }
  a.badge-contrast:focus, a.badge-contrast.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #853a32;
  background-color: #ffe2df;
  border-color: #ffd7d3; }
  .alert-primary hr {
    border-top-color: #ffc0ba; }
  .alert-primary .alert-link {
    color: #602a24; }

.alert-secondary {
  color: #835211;
  background-color: #feecd3;
  border-color: #fee4c1; }
  .alert-secondary hr {
    border-top-color: #fed9a8; }
  .alert-secondary .alert-link {
    color: #56360b; }

.alert-success {
  color: #1d623c;
  background-color: #d7f2e3;
  border-color: #c7edd8; }
  .alert-success hr {
    border-top-color: #b4e7cb; }
  .alert-success .alert-link {
    color: #113b24; }

.alert-info {
  color: #096b7c;
  background-color: #cff5fc;
  border-color: #bcf1fb; }
  .alert-info hr {
    border-top-color: #a4ecfa; }
  .alert-info .alert-link {
    color: #06424c; }

.alert-warning {
  color: #723f20;
  background-color: #f8e4d8;
  border-color: #f5d9c9; }
  .alert-warning hr {
    border-top-color: #f1cab3; }
  .alert-warning .alert-link {
    color: #4a2915; }

.alert-danger {
  color: #7f221c;
  background-color: #fdd9d7;
  border-color: #fccac7; }
  .alert-danger hr {
    border-top-color: #fbb3af; }
  .alert-danger .alert-link {
    color: #551713; }

.alert-light {
  color: #7b7f81;
  background-color: #fbfdfe;
  border-color: #fafcfd; }
  .alert-light hr {
    border-top-color: #e8f1f6; }
  .alert-light .alert-link {
    color: #626567; }

.alert-dark {
  color: #0c0c0b;
  background-color: #d1d1d0;
  border-color: #bebebd; }
  .alert-dark hr {
    border-top-color: #b1b1b0; }
  .alert-dark .alert-link {
    color: black; }

.alert-alternate {
  color: #00405f;
  background-color: #cce5f1;
  border-color: #b8daeb; }
  .alert-alternate hr {
    border-top-color: #a4d0e5; }
  .alert-alternate .alert-link {
    color: #001e2c; }

.alert-contrast {
  color: #858585;
  background-color: white;
  border-color: white; }
  .alert-contrast hr {
    border-top-color: #f2f2f2; }
  .alert-contrast .alert-link {
    color: #6c6c6c; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #ff6f61;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #27333e;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #ff6f61;
    border-color: #ff6f61; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #853a32;
  background-color: #ffd7d3; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #853a32;
    background-color: #ffc0ba; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #853a32;
    border-color: #853a32; }

.list-group-item-secondary {
  color: #835211;
  background-color: #fee4c1; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #835211;
    background-color: #fed9a8; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #835211;
    border-color: #835211; }

.list-group-item-success {
  color: #1d623c;
  background-color: #c7edd8; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #1d623c;
    background-color: #b4e7cb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #1d623c;
    border-color: #1d623c; }

.list-group-item-info {
  color: #096b7c;
  background-color: #bcf1fb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #096b7c;
    background-color: #a4ecfa; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #096b7c;
    border-color: #096b7c; }

.list-group-item-warning {
  color: #723f20;
  background-color: #f5d9c9; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #723f20;
    background-color: #f1cab3; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #723f20;
    border-color: #723f20; }

.list-group-item-danger {
  color: #7f221c;
  background-color: #fccac7; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #7f221c;
    background-color: #fbb3af; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #7f221c;
    border-color: #7f221c; }

.list-group-item-light {
  color: #7b7f81;
  background-color: #fafcfd; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #7b7f81;
    background-color: #e8f1f6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #7b7f81;
    border-color: #7b7f81; }

.list-group-item-dark {
  color: #0c0c0b;
  background-color: #bebebd; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #0c0c0b;
    background-color: #b1b1b0; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #0c0c0b;
    border-color: #0c0c0b; }

.list-group-item-alternate {
  color: #00405f;
  background-color: #b8daeb; }
  .list-group-item-alternate.list-group-item-action:hover, .list-group-item-alternate.list-group-item-action:focus {
    color: #00405f;
    background-color: #a4d0e5; }
  .list-group-item-alternate.list-group-item-action.active {
    color: #fff;
    background-color: #00405f;
    border-color: #00405f; }

.list-group-item-contrast {
  color: #858585;
  background-color: white; }
  .list-group-item-contrast.list-group-item-action:hover, .list-group-item-contrast.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-contrast.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  @media (max-width: 1200px) {
    .close {
      font-size: calc(1.275rem + 0.3vw) ; } }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #edf4f8;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #edf4f8;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #27333e; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #ff6f61 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #ff412e !important; }

.bg-secondary {
  background-color: #fc9e21 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #e78503 !important; }

.bg-success {
  background-color: #37bd74 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #2c955c !important; }

.bg-info {
  background-color: #11cdef !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0da5c0 !important; }

.bg-warning {
  background-color: #dc793e !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #c45f23 !important; }

.bg-danger {
  background-color: #f44236 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ea1b0d !important; }

.bg-light {
  background-color: #edf4f8 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #c8deea !important; }

.bg-dark {
  background-color: #181715 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: black !important; }

.bg-alternate {
  background-color: #007cb7 !important; }

a.bg-alternate:hover, a.bg-alternate:focus,
button.bg-alternate:hover,
button.bg-alternate:focus {
  background-color: #005984 !important; }

.bg-contrast {
  background-color: #fff !important; }

a.bg-contrast:hover, a.bg-contrast:focus,
button.bg-contrast:hover,
button.bg-contrast:focus {
  background-color: #e6e6e6 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #edf4f8 !important; }

.border-top {
  border-top: 1px solid #edf4f8 !important; }

.border-right {
  border-right: 1px solid #edf4f8 !important; }

.border-bottom {
  border-bottom: 1px solid #edf4f8 !important; }

.border-left {
  border-left: 1px solid #edf4f8 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #ff6f61 !important; }

.border-secondary {
  border-color: #fc9e21 !important; }

.border-success {
  border-color: #37bd74 !important; }

.border-info {
  border-color: #11cdef !important; }

.border-warning {
  border-color: #dc793e !important; }

.border-danger {
  border-color: #f44236 !important; }

.border-light {
  border-color: #edf4f8 !important; }

.border-dark {
  border-color: #181715 !important; }

.border-alternate {
  border-color: #007cb7 !important; }

.border-contrast {
  border-color: #fff !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: 100 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: 900 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #ff6f61 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #ff2915 !important; }

.text-secondary {
  color: #fc9e21 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #ce7703 !important; }

.text-success {
  color: #37bd74 !important; }

a.text-success:hover, a.text-success:focus {
  color: #268250 !important; }

.text-info {
  color: #11cdef !important; }

a.text-info:hover, a.text-info:focus {
  color: #0b90a8 !important; }

.text-warning {
  color: #dc793e !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ae5520 !important; }

.text-danger {
  color: #f44236 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #d2180b !important; }

.text-light {
  color: #edf4f8 !important; }

a.text-light:hover, a.text-light:focus {
  color: #b6d2e3 !important; }

.text-dark {
  color: #181715 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: black !important; }

.text-alternate {
  color: #007cb7 !important; }

a.text-alternate:hover, a.text-alternate:focus {
  color: #00486b !important; }

.text-contrast {
  color: #fff !important; }

a.text-contrast:hover, a.text-contrast:focus {
  color: #d9d9d9 !important; }

.text-body {
  color: #27333e !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #edf4f8; }
  .table .thead-dark th {
    color: inherit;
    border-color: #edf4f8; } }

/* ==========================================================================
    [2. RESET STYLES]
========================================================================== */
body {
  overflow-x: hidden;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important; }

a {
  transition: all 0.3s ease-in; }
  a, a:focus, a:active, a:hover {
    outline: 0 !important;
    text-decoration: none; }

hr {
  display: block;
  height: 1px;
  border: 0;
  margin: 1em 0;
  padding: 0; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

/* ==========================================================================
    [3. ANIMATIONS]
========================================================================== */
@keyframes clockwise {
  0% {
    transform: rotate(0deg) translate(-165px) rotate(0deg); }
  100% {
    transform: rotate(360deg) translate(-165px) rotate(-360deg); } }

@keyframes anti-clockwise {
  0% {
    transform: rotate(0deg) translate(165px) rotate(0deg); }
  100% {
    transform: rotate(-360deg) translate(165px) rotate(360deg); } }

@keyframes rotating {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(-360deg); } }

@keyframes rotating-diagonal {
  0% {
    transform: translate(-300px, 150px) rotate(0deg); }
  100% {
    transform: translate(300px, -150px) rotate(180deg); } }

.animation--clockwise {
  animation: clockwise 30s linear infinite normal; }

.animation--anti-clockwise {
  animation: anti-clockwise 30s linear infinite normal; }

.animation--rotating {
  animation: rotating 15s linear infinite normal; }

.animation--rotating-diagonal {
  animation: rotating-diagonal 15s linear infinite alternate; }

@keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes jump {
  0% {
    top: 0; }
  50% {
    top: 50px; }
  100% {
    top: 0; } }

.animation {
  transform-style: preserve-3d; }

.typed-cursor {
  opacity: 1;
  animation: blink 0.7s infinite; }

@keyframes floating {
  from {
    transform: translate(0, 0px); }
  65% {
    transform: translate(0, 10px); }
  to {
    transform: translate(0, 0px); } }

.floating {
  animation: floating 4s ease-in-out infinite;
  filter: none; }

@keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes bubble-v {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(-640px); } }

@keyframes bubble-v-left {
  0% {
    margin-bottom: 0;
    opacity: .1; }
  20% {
    opacity: 1; }
  100% {
    margin-bottom: 640px;
    opacity: 1;
    left: 0; } }

@keyframes bubble-v-right {
  0% {
    margin-bottom: 0;
    opacity: .1; }
  20% {
    opacity: 1; }
  100% {
    margin-bottom: 640px;
    opacity: 1;
    left: 100%; } }

@keyframes shake-v {
  0% {
    margin-left: 0; }
  100% {
    margin-left: 20px; } }

/* ==========================================================================
    [5. FORMS]
========================================================================== */
/* 4.2 Buttons ======================================================= */
button:focus {
  outline: none; }

.btn {
  transition: all 0.3s ease;
  text-decoration: none;
  letter-spacing: 1.45px;
  font-weight: 400;
  padding: 0.5rem 1.25rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  white-space: normal;
  text-align: left; }
  .btn .icon {
    margin-right: 8px; }

.btn-link {
  box-shadow: none;
  cursor: pointer; }

.btn-xs {
  padding: 0.125rem 0.25rem;
  font-size: 0.25rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.5rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

.btn-md {
  padding: 0.375rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

.btn-xl {
  padding: 0.625rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

.btn-rounded {
  border-radius: 10rem; }

.btn-circle {
  width: 3rem;
  height: 3rem;
  padding: 0;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem; }
  .btn-circle.btn-xs {
    width: 1rem;
    height: 1rem;
    font-size: 0.25rem; }
  .btn-circle.btn-sm, .btn-group-sm > .btn-circle.btn {
    width: 2rem;
    height: 2rem;
    font-size: 0.625rem; }
  .btn-circle.btn-md {
    width: 3rem;
    height: 3rem;
    font-size: 1rem; }
  .btn-circle.btn-lg, .btn-group-lg > .btn-circle.btn {
    width: 4rem;
    height: 4rem;
    font-size: 1.375rem; }
  .btn-circle.btn-xl {
    width: 5rem;
    height: 5rem;
    font-size: 1.75rem; }

.btn-download {
  min-width: 200px;
  text-align: left;
  display: flex !important;
  align-items: center; }
  .btn-download p {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.45; }
  .btn-download .small {
    margin-top: -2px;
    display: block; }

.input-group .btn:not(:first-child) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn-primary:hover {
  box-shadow: 0 5px 10px rgba(255, 111, 97, 0.4) !important; }

.btn-secondary:hover {
  box-shadow: 0 5px 10px rgba(252, 158, 33, 0.4) !important; }

.btn-success:hover {
  box-shadow: 0 5px 10px rgba(55, 189, 116, 0.4) !important; }

.btn-info:hover {
  box-shadow: 0 5px 10px rgba(17, 205, 239, 0.4) !important; }

.btn-warning:hover {
  box-shadow: 0 5px 10px rgba(220, 121, 62, 0.4) !important; }

.btn-danger:hover {
  box-shadow: 0 5px 10px rgba(244, 66, 54, 0.4) !important; }

.btn-light:hover {
  box-shadow: 0 5px 10px rgba(237, 244, 248, 0.4) !important; }

.btn-dark:hover {
  box-shadow: 0 5px 10px rgba(24, 23, 21, 0.4) !important; }

.btn-alternate:hover {
  box-shadow: 0 5px 10px rgba(0, 124, 183, 0.4) !important; }

.btn-contrast:hover {
  box-shadow: 0 5px 10px rgba(255, 255, 255, 0.4) !important; }

.btn-accent {
  color: #212529;
  background-color: #ff6f61;
  border-color: #ff6f61; }
  .btn-accent:hover {
    color: #fff;
    background-color: #ff4c3b;
    border-color: #ff412e; }
  .btn-accent:focus, .btn-accent.focus {
    color: #fff;
    background-color: #ff4c3b;
    border-color: #ff412e;
    box-shadow: 0 0 0 0.2rem rgba(222, 100, 89, 0.5); }
  .btn-accent.disabled, .btn-accent:disabled {
    color: #212529;
    background-color: #ff6f61;
    border-color: #ff6f61; }
  .btn-accent:not(:disabled):not(.disabled):active, .btn-accent:not(:disabled):not(.disabled).active,
  .show > .btn-accent.dropdown-toggle {
    color: #fff;
    background-color: #ff412e;
    border-color: #ff3521; }
    .btn-accent:not(:disabled):not(.disabled):active:focus, .btn-accent:not(:disabled):not(.disabled).active:focus,
    .show > .btn-accent.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 100, 89, 0.5); }
  .btn-accent:hover {
    box-shadow: 0 5px 10px rgba(255, 111, 97, 0.4) !important; }

.btn-outline-accent {
  color: #ff6f61;
  border-color: #ff6f61; }
  .btn-outline-accent:hover {
    color: #212529;
    background-color: #ff6f61;
    border-color: #ff6f61; }
  .btn-outline-accent:focus, .btn-outline-accent.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 111, 97, 0.5); }
  .btn-outline-accent.disabled, .btn-outline-accent:disabled {
    color: #ff6f61;
    background-color: transparent; }
  .btn-outline-accent:not(:disabled):not(.disabled):active, .btn-outline-accent:not(:disabled):not(.disabled).active,
  .show > .btn-outline-accent.dropdown-toggle {
    color: #212529;
    background-color: #ff6f61;
    border-color: #ff6f61; }
    .btn-outline-accent:not(:disabled):not(.disabled):active:focus, .btn-outline-accent:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-accent.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 111, 97, 0.5); }
  .btn-outline-accent:hover {
    box-shadow: 0 5px 10px rgba(255, 111, 97, 0.4) !important; }

/* 4.1 Form ======================================================= */
form[data-response-message-animation],
form[data-response-message-animation] + .response-message, .form[data-response-message-animation],
.form[data-response-message-animation] + .response-message {
  transition: transform .3s ease-in, opacity .3s ease-out; }

form[data-response-message-animation].submitted + .response-message, .form[data-response-message-animation].submitted + .response-message {
  z-index: 1;
  opacity: 1;
  position: relative; }

form[data-response-message-animation].submitted,
form[data-response-message-animation] + .response-message, .form[data-response-message-animation].submitted,
.form[data-response-message-animation] + .response-message {
  opacity: 0;
  position: absolute;
  top: 0; }

form[data-response-message-animation] + .response-message, .form[data-response-message-animation] + .response-message {
  z-index: -1; }

form[data-response-message-animation="slide-in-up"].submitted + .response-message, .form[data-response-message-animation="slide-in-up"].submitted + .response-message {
  transform: translateY(0); }

form[data-response-message-animation="slide-in-up"].submitted,
form[data-response-message-animation="slide-in-up"] + .response-message, .form[data-response-message-animation="slide-in-up"].submitted,
.form[data-response-message-animation="slide-in-up"] + .response-message {
  transform: translateY(100%); }

form[data-response-message-animation="slide-in-left"].submitted + .response-message, .form[data-response-message-animation="slide-in-left"].submitted + .response-message {
  transform: translateX(0); }

form[data-response-message-animation="slide-in-left"].submitted,
form[data-response-message-animation="slide-in-left"] + .response-message, .form[data-response-message-animation="slide-in-left"].submitted,
.form[data-response-message-animation="slide-in-left"] + .response-message {
  transform: translateX(100%); }

form.cozy .form-group, .form.cozy .form-group {
  margin-bottom: 30px; }

form .form-group label.error, .form .form-group label.error {
  color: #f44236;
  font-size: 0.85rem; }

form .form-group.has-icon, .form .form-group.has-icon {
  display: flex;
  align-items: center;
  position: relative; }
  form .form-group.has-icon .form-control, .form .form-group.has-icon .form-control {
    order: 1;
    padding-right: 2.5rem;
    flex: 0 0 100%; }
    form .form-group.has-icon .form-control.is-valid + .icon, .form .form-group.has-icon .form-control.is-valid + .icon {
      color: #37bd74; }
    form .form-group.has-icon .form-control.is-invalid + .icon, .form .form-group.has-icon .form-control.is-invalid + .icon {
      color: #f44236; }
  form .form-group.has-icon .icon, .form .form-group.has-icon .icon {
    order: 2;
    margin-right: 15px;
    margin-left: 12px;
    position: absolute;
    right: 0; }
  form .form-group.has-icon.icon-left .form-control, .form .form-group.has-icon.icon-left .form-control {
    order: 2;
    padding-left: 2.5rem;
    padding-right: 0; }
  form .form-group.has-icon.icon-left .icon, .form .form-group.has-icon.icon-left .icon {
    order: 1;
    left: 5px;
    right: auto; }

form .error, .form .error {
  color: #f44236; }

/* 8.2 Inputs ======================================================= */
.form-control {
  color: #27333e;
  border-color: #d3d4d5; }
  .form-control.form-control-rounded {
    border-radius: 0.25rem; }
  .form-control::placeholder {
    opacity: 0.5;
    font-size: 0.875rem; }

select[multiple].form-control.form-control-rounded, textarea.form-control.form-control-rounded {
  padding-left: 1rem;
  border-radius: 1rem; }

.control-label {
  color: #6c757d; }

.control {
  position: relative;
  z-index: 1;
  vertical-align: top; }
  .control .form-control {
    position: relative;
    display: block; }
    .control .form-control:focus {
      outline: none; }
  .control .control-label {
    display: inline-block;
    user-select: none;
    transition: all .3s ease-in-out;
    font-weight: 400; }
  .control select {
    appearance: none; }
  .control .toggler {
    position: absolute;
    top: 50%;
    margin-top: -4px;
    right: 10px;
    line-height: 1;
    z-index: 1; }

.label-content {
  position: relative;
  display: block;
  padding: 1.6em 0;
  width: 100%; }

.input-group-register {
  position: relative; }
  .input-group-register label {
    position: absolute;
    bottom: -4px;
    margin-bottom: 0; }
  .input-group-register .form-control {
    padding: 2.5rem; }
  .input-group-register .btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px; }

/* ==========================================================================
    [6. UTILITIES]
========================================================================== */
/* 2.1 Background ======================================================= */
.image-background {
  position: relative;
  background-attachment: scroll;
  background-position: center center;
  background-repeat: no-repeat; }
  .image-background.cover {
    background-size: cover; }
  .image-background.contain {
    background-size: contain; }
  .image-background.left-bottom {
    background-position: 0 bottom; }
  .image-background.center-top {
    background-position: center top; }
  .image-background.center-bottom {
    background-position: center bottom; }
  .image-background.p100-top {
    background-position: 100% top; }
  .image-background.p100-bottom {
    background-position: 100% bottom; }

.parallax {
  position: static; }
  .parallax, .parallax.blurred:before {
    background-attachment: fixed; }
  .parallax.blurred:before {
    position: absolute; }

.bg-primary.edge.bottom-right {
  position: relative; }
  .bg-primary.edge.bottom-right::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(255,111,97)' fill-opacity='1'%3E%3Cpolygon points='0,0 100,0 0,310'%3E%3C/polygon%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: 310px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
    bottom: -310px; }

.bg-primary.edge.top-left {
  position: relative; }
  .bg-primary.edge.top-left::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(255,111,97)' fill-opacity='1'%3E%3Cpolygon points='0,310 100,310 100,0'%3E%3C/polygon%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: 310px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
    top: -310px; }

.bg-secondary.edge.bottom-right {
  position: relative; }
  .bg-secondary.edge.bottom-right::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(252,158,33)' fill-opacity='1'%3E%3Cpolygon points='0,0 100,0 0,310'%3E%3C/polygon%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: 310px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
    bottom: -310px; }

.bg-secondary.edge.top-left {
  position: relative; }
  .bg-secondary.edge.top-left::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(252,158,33)' fill-opacity='1'%3E%3Cpolygon points='0,310 100,310 100,0'%3E%3C/polygon%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: 310px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
    top: -310px; }

.bg-success.edge.bottom-right {
  position: relative; }
  .bg-success.edge.bottom-right::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(55,189,116)' fill-opacity='1'%3E%3Cpolygon points='0,0 100,0 0,310'%3E%3C/polygon%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: 310px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
    bottom: -310px; }

.bg-success.edge.top-left {
  position: relative; }
  .bg-success.edge.top-left::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(55,189,116)' fill-opacity='1'%3E%3Cpolygon points='0,310 100,310 100,0'%3E%3C/polygon%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: 310px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
    top: -310px; }

.bg-info.edge.bottom-right {
  position: relative; }
  .bg-info.edge.bottom-right::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(17,205,239)' fill-opacity='1'%3E%3Cpolygon points='0,0 100,0 0,310'%3E%3C/polygon%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: 310px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
    bottom: -310px; }

.bg-info.edge.top-left {
  position: relative; }
  .bg-info.edge.top-left::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(17,205,239)' fill-opacity='1'%3E%3Cpolygon points='0,310 100,310 100,0'%3E%3C/polygon%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: 310px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
    top: -310px; }

.bg-warning.edge.bottom-right {
  position: relative; }
  .bg-warning.edge.bottom-right::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(220,121,62)' fill-opacity='1'%3E%3Cpolygon points='0,0 100,0 0,310'%3E%3C/polygon%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: 310px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
    bottom: -310px; }

.bg-warning.edge.top-left {
  position: relative; }
  .bg-warning.edge.top-left::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(220,121,62)' fill-opacity='1'%3E%3Cpolygon points='0,310 100,310 100,0'%3E%3C/polygon%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: 310px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
    top: -310px; }

.bg-danger.edge.bottom-right {
  position: relative; }
  .bg-danger.edge.bottom-right::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(244,66,54)' fill-opacity='1'%3E%3Cpolygon points='0,0 100,0 0,310'%3E%3C/polygon%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: 310px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
    bottom: -310px; }

.bg-danger.edge.top-left {
  position: relative; }
  .bg-danger.edge.top-left::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(244,66,54)' fill-opacity='1'%3E%3Cpolygon points='0,310 100,310 100,0'%3E%3C/polygon%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: 310px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
    top: -310px; }

.bg-light.edge.bottom-right {
  position: relative; }
  .bg-light.edge.bottom-right::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(237,244,248)' fill-opacity='1'%3E%3Cpolygon points='0,0 100,0 0,310'%3E%3C/polygon%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: 310px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
    bottom: -310px; }

.bg-light.edge.top-left {
  position: relative; }
  .bg-light.edge.top-left::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(237,244,248)' fill-opacity='1'%3E%3Cpolygon points='0,310 100,310 100,0'%3E%3C/polygon%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: 310px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
    top: -310px; }

.bg-dark.edge.bottom-right {
  position: relative; }
  .bg-dark.edge.bottom-right::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(24,23,21)' fill-opacity='1'%3E%3Cpolygon points='0,0 100,0 0,310'%3E%3C/polygon%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: 310px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
    bottom: -310px; }

.bg-dark.edge.top-left {
  position: relative; }
  .bg-dark.edge.top-left::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(24,23,21)' fill-opacity='1'%3E%3Cpolygon points='0,310 100,310 100,0'%3E%3C/polygon%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: 310px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
    top: -310px; }

.bg-alternate.edge.bottom-right {
  position: relative; }
  .bg-alternate.edge.bottom-right::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(0,124,183)' fill-opacity='1'%3E%3Cpolygon points='0,0 100,0 0,310'%3E%3C/polygon%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: 310px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
    bottom: -310px; }

.bg-alternate.edge.top-left {
  position: relative; }
  .bg-alternate.edge.top-left::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(0,124,183)' fill-opacity='1'%3E%3Cpolygon points='0,310 100,310 100,0'%3E%3C/polygon%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: 310px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
    top: -310px; }

.bg-contrast.edge.bottom-right {
  position: relative; }
  .bg-contrast.edge.bottom-right::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(255,255,255)' fill-opacity='1'%3E%3Cpolygon points='0,0 100,0 0,310'%3E%3C/polygon%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: 310px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
    bottom: -310px; }

.bg-contrast.edge.top-left {
  position: relative; }
  .bg-contrast.edge.top-left::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(255,255,255)' fill-opacity='1'%3E%3Cpolygon points='0,310 100,310 100,0'%3E%3C/polygon%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: 310px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
    top: -310px; }

/* 2.2 Border ======================================================= */
.border-t {
  border-top: 1px solid #edf4f8; }

.border-r {
  border-right: 1px solid #edf4f8; }

.border-b {
  border-bottom: 1px solid #edf4f8; }

.border-l {
  border-left: 1px solid #edf4f8; }

@media (min-width: 576px) {
  .border-sm-t {
    border-top: 1px solid #edf4f8; }
  .border-sm-r {
    border-right: 1px solid #edf4f8; }
  .border-sm-b {
    border-bottom: 1px solid #edf4f8; }
  .border-sm-l {
    border-left: 1px solid #edf4f8; } }

@media (min-width: 768px) {
  .border-md-t {
    border-top: 1px solid #edf4f8; }
  .border-md-r {
    border-right: 1px solid #edf4f8; }
  .border-md-b {
    border-bottom: 1px solid #edf4f8; }
  .border-md-l {
    border-left: 1px solid #edf4f8; } }

@media (min-width: 992px) {
  .border-lg-t {
    border-top: 1px solid #edf4f8; }
  .border-lg-r {
    border-right: 1px solid #edf4f8; }
  .border-lg-b {
    border-bottom: 1px solid #edf4f8; }
  .border-lg-l {
    border-left: 1px solid #edf4f8; } }

@media (min-width: 1200px) {
  .border-xl-t {
    border-top: 1px solid #edf4f8; }
  .border-xl-r {
    border-right: 1px solid #edf4f8; }
  .border-xl-b {
    border-bottom: 1px solid #edf4f8; }
  .border-xl-l {
    border-left: 1px solid #edf4f8; } }

.shadow-primary {
  box-shadow: 0 1px 10px rgba(255, 111, 97, 0.4) !important; }

.shadow-secondary {
  box-shadow: 0 1px 10px rgba(252, 158, 33, 0.4) !important; }

.shadow-success {
  box-shadow: 0 1px 10px rgba(55, 189, 116, 0.4) !important; }

.shadow-info {
  box-shadow: 0 1px 10px rgba(17, 205, 239, 0.4) !important; }

.shadow-warning {
  box-shadow: 0 1px 10px rgba(220, 121, 62, 0.4) !important; }

.shadow-danger {
  box-shadow: 0 1px 10px rgba(244, 66, 54, 0.4) !important; }

.shadow-light {
  box-shadow: 0 1px 10px rgba(237, 244, 248, 0.4) !important; }

.shadow-dark {
  box-shadow: 0 1px 10px rgba(24, 23, 21, 0.4) !important; }

.shadow-alternate {
  box-shadow: 0 1px 10px rgba(0, 124, 183, 0.4) !important; }

.shadow-contrast {
  box-shadow: 0 1px 10px rgba(255, 255, 255, 0.4) !important; }

.shadow {
  box-shadow: 0 1px 9px 1px rgba(0, 0, 0, 0.15); }

.drop-shadow {
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25)); }

.shadow-box {
  transition: box-shadow .3s linear;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.03); }

.shadow-hover {
  transition: box-shadow .3s ease-in-out; }
  .shadow-hover:hover {
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.085) !important; }

.border-input {
  border: 1px solid #d3d4d5; }

.rounded-circle img {
  border-radius: 50%; }

.bw-2 {
  border-width: 2px; }

.brand-facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998; }
  .brand-facebook:hover {
    color: #fff;
    background-color: #30497c;
    border-color: #2d4373; }
  .brand-facebook:focus, .brand-facebook.focus {
    color: #fff;
    background-color: #30497c;
    border-color: #2d4373;
    box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5); }
  .brand-facebook.disabled, .brand-facebook:disabled {
    color: #fff;
    background-color: #3b5998;
    border-color: #3b5998; }
  .brand-facebook:not(:disabled):not(.disabled):active, .brand-facebook:not(:disabled):not(.disabled).active,
  .show > .brand-facebook.dropdown-toggle {
    color: #fff;
    background-color: #2d4373;
    border-color: #293e6a; }
    .brand-facebook:not(:disabled):not(.disabled):active:focus, .brand-facebook:not(:disabled):not(.disabled).active:focus,
    .show > .brand-facebook.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5); }
  .brand-facebook:hover {
    box-shadow: 0 5px 10px rgba(59, 89, 152, 0.4) !important; }

.brand-google {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39; }
  .brand-google:hover {
    color: #fff;
    background-color: #cd3623;
    border-color: #c23321; }
  .brand-google:focus, .brand-google.focus {
    color: #fff;
    background-color: #cd3623;
    border-color: #c23321;
    box-shadow: 0 0 0 0.2rem rgba(226, 102, 87, 0.5); }
  .brand-google.disabled, .brand-google:disabled {
    color: #fff;
    background-color: #dd4b39;
    border-color: #dd4b39; }
  .brand-google:not(:disabled):not(.disabled):active, .brand-google:not(:disabled):not(.disabled).active,
  .show > .brand-google.dropdown-toggle {
    color: #fff;
    background-color: #c23321;
    border-color: #b7301f; }
    .brand-google:not(:disabled):not(.disabled):active:focus, .brand-google:not(:disabled):not(.disabled).active:focus,
    .show > .brand-google.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(226, 102, 87, 0.5); }
  .brand-google:hover {
    box-shadow: 0 5px 10px rgba(221, 75, 57, 0.4) !important; }

.brand-gplus {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39; }
  .brand-gplus:hover {
    color: #fff;
    background-color: #cd3623;
    border-color: #c23321; }
  .brand-gplus:focus, .brand-gplus.focus {
    color: #fff;
    background-color: #cd3623;
    border-color: #c23321;
    box-shadow: 0 0 0 0.2rem rgba(226, 102, 87, 0.5); }
  .brand-gplus.disabled, .brand-gplus:disabled {
    color: #fff;
    background-color: #dd4b39;
    border-color: #dd4b39; }
  .brand-gplus:not(:disabled):not(.disabled):active, .brand-gplus:not(:disabled):not(.disabled).active,
  .show > .brand-gplus.dropdown-toggle {
    color: #fff;
    background-color: #c23321;
    border-color: #b7301f; }
    .brand-gplus:not(:disabled):not(.disabled):active:focus, .brand-gplus:not(:disabled):not(.disabled).active:focus,
    .show > .brand-gplus.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(226, 102, 87, 0.5); }
  .brand-gplus:hover {
    box-shadow: 0 5px 10px rgba(221, 75, 57, 0.4) !important; }

.brand-twitter {
  color: #fff;
  background-color: #00aced;
  border-color: #00aced; }
  .brand-twitter:hover {
    color: #fff;
    background-color: #0090c7;
    border-color: #0087ba; }
  .brand-twitter:focus, .brand-twitter.focus {
    color: #fff;
    background-color: #0090c7;
    border-color: #0087ba;
    box-shadow: 0 0 0 0.2rem rgba(38, 184, 240, 0.5); }
  .brand-twitter.disabled, .brand-twitter:disabled {
    color: #fff;
    background-color: #00aced;
    border-color: #00aced; }
  .brand-twitter:not(:disabled):not(.disabled):active, .brand-twitter:not(:disabled):not(.disabled).active,
  .show > .brand-twitter.dropdown-toggle {
    color: #fff;
    background-color: #0087ba;
    border-color: #007ead; }
    .brand-twitter:not(:disabled):not(.disabled):active:focus, .brand-twitter:not(:disabled):not(.disabled).active:focus,
    .show > .brand-twitter.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 184, 240, 0.5); }
  .brand-twitter:hover {
    box-shadow: 0 5px 10px rgba(0, 172, 237, 0.4) !important; }

.brand-linkedin {
  color: #fff;
  background-color: #007bb6;
  border-color: #007bb6; }
  .brand-linkedin:hover {
    color: #fff;
    background-color: #006190;
    border-color: #005983; }
  .brand-linkedin:focus, .brand-linkedin.focus {
    color: #fff;
    background-color: #006190;
    border-color: #005983;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 193, 0.5); }
  .brand-linkedin.disabled, .brand-linkedin:disabled {
    color: #fff;
    background-color: #007bb6;
    border-color: #007bb6; }
  .brand-linkedin:not(:disabled):not(.disabled):active, .brand-linkedin:not(:disabled):not(.disabled).active,
  .show > .brand-linkedin.dropdown-toggle {
    color: #fff;
    background-color: #005983;
    border-color: #005076; }
    .brand-linkedin:not(:disabled):not(.disabled):active:focus, .brand-linkedin:not(:disabled):not(.disabled).active:focus,
    .show > .brand-linkedin.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 193, 0.5); }
  .brand-linkedin:hover {
    box-shadow: 0 5px 10px rgba(0, 123, 182, 0.4) !important; }

.brand-pinterest {
  color: #fff;
  background-color: #cb2027;
  border-color: #cb2027; }
  .brand-pinterest:hover {
    color: #fff;
    background-color: #aa1b21;
    border-color: #9f191f; }
  .brand-pinterest:focus, .brand-pinterest.focus {
    color: #fff;
    background-color: #aa1b21;
    border-color: #9f191f;
    box-shadow: 0 0 0 0.2rem rgba(211, 65, 71, 0.5); }
  .brand-pinterest.disabled, .brand-pinterest:disabled {
    color: #fff;
    background-color: #cb2027;
    border-color: #cb2027; }
  .brand-pinterest:not(:disabled):not(.disabled):active, .brand-pinterest:not(:disabled):not(.disabled).active,
  .show > .brand-pinterest.dropdown-toggle {
    color: #fff;
    background-color: #9f191f;
    border-color: #94171c; }
    .brand-pinterest:not(:disabled):not(.disabled):active:focus, .brand-pinterest:not(:disabled):not(.disabled).active:focus,
    .show > .brand-pinterest.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(211, 65, 71, 0.5); }
  .brand-pinterest:hover {
    box-shadow: 0 5px 10px rgba(203, 32, 39, 0.4) !important; }

.brand-git {
  color: #fff;
  background-color: #666666;
  border-color: #666666; }
  .brand-git:hover {
    color: #fff;
    background-color: #535353;
    border-color: #4d4d4d; }
  .brand-git:focus, .brand-git.focus {
    color: #fff;
    background-color: #535353;
    border-color: #4d4d4d;
    box-shadow: 0 0 0 0.2rem rgba(125, 125, 125, 0.5); }
  .brand-git.disabled, .brand-git:disabled {
    color: #fff;
    background-color: #666666;
    border-color: #666666; }
  .brand-git:not(:disabled):not(.disabled):active, .brand-git:not(:disabled):not(.disabled).active,
  .show > .brand-git.dropdown-toggle {
    color: #fff;
    background-color: #4d4d4d;
    border-color: #464646; }
    .brand-git:not(:disabled):not(.disabled):active:focus, .brand-git:not(:disabled):not(.disabled).active:focus,
    .show > .brand-git.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(125, 125, 125, 0.5); }
  .brand-git:hover {
    box-shadow: 0 5px 10px rgba(102, 102, 102, 0.4) !important; }

.brand-tumblr {
  color: #fff;
  background-color: #32506d;
  border-color: #32506d; }
  .brand-tumblr:hover {
    color: #fff;
    background-color: #263d53;
    border-color: #22364a; }
  .brand-tumblr:focus, .brand-tumblr.focus {
    color: #fff;
    background-color: #263d53;
    border-color: #22364a;
    box-shadow: 0 0 0 0.2rem rgba(81, 106, 131, 0.5); }
  .brand-tumblr.disabled, .brand-tumblr:disabled {
    color: #fff;
    background-color: #32506d;
    border-color: #32506d; }
  .brand-tumblr:not(:disabled):not(.disabled):active, .brand-tumblr:not(:disabled):not(.disabled).active,
  .show > .brand-tumblr.dropdown-toggle {
    color: #fff;
    background-color: #22364a;
    border-color: #1e3041; }
    .brand-tumblr:not(:disabled):not(.disabled):active:focus, .brand-tumblr:not(:disabled):not(.disabled).active:focus,
    .show > .brand-tumblr.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(81, 106, 131, 0.5); }
  .brand-tumblr:hover {
    box-shadow: 0 5px 10px rgba(50, 80, 109, 0.4) !important; }

.brand-vimeo {
  color: #212529;
  background-color: #aad450;
  border-color: #aad450; }
  .brand-vimeo:hover {
    color: #212529;
    background-color: #9bcc32;
    border-color: #93c130; }
  .brand-vimeo:focus, .brand-vimeo.focus {
    color: #212529;
    background-color: #9bcc32;
    border-color: #93c130;
    box-shadow: 0 0 0 0.2rem rgba(149, 186, 74, 0.5); }
  .brand-vimeo.disabled, .brand-vimeo:disabled {
    color: #212529;
    background-color: #aad450;
    border-color: #aad450; }
  .brand-vimeo:not(:disabled):not(.disabled):active, .brand-vimeo:not(:disabled):not(.disabled).active,
  .show > .brand-vimeo.dropdown-toggle {
    color: #212529;
    background-color: #93c130;
    border-color: #8bb72d; }
    .brand-vimeo:not(:disabled):not(.disabled):active:focus, .brand-vimeo:not(:disabled):not(.disabled).active:focus,
    .show > .brand-vimeo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(149, 186, 74, 0.5); }
  .brand-vimeo:hover {
    box-shadow: 0 5px 10px rgba(170, 212, 80, 0.4) !important; }

.brand-youtube {
  color: #fff;
  background-color: #bb0000;
  border-color: #bb0000; }
  .brand-youtube:hover {
    color: #fff;
    background-color: #950000;
    border-color: #880000; }
  .brand-youtube:focus, .brand-youtube.focus {
    color: #fff;
    background-color: #950000;
    border-color: #880000;
    box-shadow: 0 0 0 0.2rem rgba(197, 38, 38, 0.5); }
  .brand-youtube.disabled, .brand-youtube:disabled {
    color: #fff;
    background-color: #bb0000;
    border-color: #bb0000; }
  .brand-youtube:not(:disabled):not(.disabled):active, .brand-youtube:not(:disabled):not(.disabled).active,
  .show > .brand-youtube.dropdown-toggle {
    color: #fff;
    background-color: #880000;
    border-color: #7b0000; }
    .brand-youtube:not(:disabled):not(.disabled):active:focus, .brand-youtube:not(:disabled):not(.disabled).active:focus,
    .show > .brand-youtube.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(197, 38, 38, 0.5); }
  .brand-youtube:hover {
    box-shadow: 0 5px 10px rgba(187, 0, 0, 0.4) !important; }

.brand-flickr {
  color: #fff;
  background-color: #ff0084;
  border-color: #ff0084; }
  .brand-flickr:hover {
    color: #fff;
    background-color: #d90070;
    border-color: #cc006a; }
  .brand-flickr:focus, .brand-flickr.focus {
    color: #fff;
    background-color: #d90070;
    border-color: #cc006a;
    box-shadow: 0 0 0 0.2rem rgba(255, 38, 150, 0.5); }
  .brand-flickr.disabled, .brand-flickr:disabled {
    color: #fff;
    background-color: #ff0084;
    border-color: #ff0084; }
  .brand-flickr:not(:disabled):not(.disabled):active, .brand-flickr:not(:disabled):not(.disabled).active,
  .show > .brand-flickr.dropdown-toggle {
    color: #fff;
    background-color: #cc006a;
    border-color: #bf0063; }
    .brand-flickr:not(:disabled):not(.disabled):active:focus, .brand-flickr:not(:disabled):not(.disabled).active:focus,
    .show > .brand-flickr.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 38, 150, 0.5); }
  .brand-flickr:hover {
    box-shadow: 0 5px 10px rgba(255, 0, 132, 0.4) !important; }

.brand-reddit {
  color: #fff;
  background-color: #ff4500;
  border-color: #ff4500; }
  .brand-reddit:hover {
    color: #fff;
    background-color: #d93b00;
    border-color: #cc3700; }
  .brand-reddit:focus, .brand-reddit.focus {
    color: #fff;
    background-color: #d93b00;
    border-color: #cc3700;
    box-shadow: 0 0 0 0.2rem rgba(255, 97, 38, 0.5); }
  .brand-reddit.disabled, .brand-reddit:disabled {
    color: #fff;
    background-color: #ff4500;
    border-color: #ff4500; }
  .brand-reddit:not(:disabled):not(.disabled):active, .brand-reddit:not(:disabled):not(.disabled).active,
  .show > .brand-reddit.dropdown-toggle {
    color: #fff;
    background-color: #cc3700;
    border-color: #bf3400; }
    .brand-reddit:not(:disabled):not(.disabled):active:focus, .brand-reddit:not(:disabled):not(.disabled).active:focus,
    .show > .brand-reddit.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 97, 38, 0.5); }
  .brand-reddit:hover {
    box-shadow: 0 5px 10px rgba(255, 69, 0, 0.4) !important; }

.brand-dribbble {
  color: #fff;
  background-color: #ea4c89;
  border-color: #ea4c89; }
  .brand-dribbble:hover {
    color: #fff;
    background-color: #e62a72;
    border-color: #e51e6b; }
  .brand-dribbble:focus, .brand-dribbble.focus {
    color: #fff;
    background-color: #e62a72;
    border-color: #e51e6b;
    box-shadow: 0 0 0 0.2rem rgba(237, 103, 155, 0.5); }
  .brand-dribbble.disabled, .brand-dribbble:disabled {
    color: #fff;
    background-color: #ea4c89;
    border-color: #ea4c89; }
  .brand-dribbble:not(:disabled):not(.disabled):active, .brand-dribbble:not(:disabled):not(.disabled).active,
  .show > .brand-dribbble.dropdown-toggle {
    color: #fff;
    background-color: #e51e6b;
    border-color: #dc1a65; }
    .brand-dribbble:not(:disabled):not(.disabled):active:focus, .brand-dribbble:not(:disabled):not(.disabled).active:focus,
    .show > .brand-dribbble.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(237, 103, 155, 0.5); }
  .brand-dribbble:hover {
    box-shadow: 0 5px 10px rgba(234, 76, 137, 0.4) !important; }

.brand-skype {
  color: #fff;
  background-color: #00aff0;
  border-color: #00aff0; }
  .brand-skype:hover {
    color: #fff;
    background-color: #0093ca;
    border-color: #008abd; }
  .brand-skype:focus, .brand-skype.focus {
    color: #fff;
    background-color: #0093ca;
    border-color: #008abd;
    box-shadow: 0 0 0 0.2rem rgba(38, 187, 242, 0.5); }
  .brand-skype.disabled, .brand-skype:disabled {
    color: #fff;
    background-color: #00aff0;
    border-color: #00aff0; }
  .brand-skype:not(:disabled):not(.disabled):active, .brand-skype:not(:disabled):not(.disabled).active,
  .show > .brand-skype.dropdown-toggle {
    color: #fff;
    background-color: #008abd;
    border-color: #0081b0; }
    .brand-skype:not(:disabled):not(.disabled):active:focus, .brand-skype:not(:disabled):not(.disabled).active:focus,
    .show > .brand-skype.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 187, 242, 0.5); }
  .brand-skype:hover {
    box-shadow: 0 5px 10px rgba(0, 175, 240, 0.4) !important; }

.brand-instagram {
  color: #fff;
  background-color: #517fa4;
  border-color: #517fa4; }
  .brand-instagram:hover {
    color: #fff;
    background-color: #446b8a;
    border-color: #406582; }
  .brand-instagram:focus, .brand-instagram.focus {
    color: #fff;
    background-color: #446b8a;
    border-color: #406582;
    box-shadow: 0 0 0 0.2rem rgba(107, 146, 178, 0.5); }
  .brand-instagram.disabled, .brand-instagram:disabled {
    color: #fff;
    background-color: #517fa4;
    border-color: #517fa4; }
  .brand-instagram:not(:disabled):not(.disabled):active, .brand-instagram:not(:disabled):not(.disabled).active,
  .show > .brand-instagram.dropdown-toggle {
    color: #fff;
    background-color: #406582;
    border-color: #3c5e79; }
    .brand-instagram:not(:disabled):not(.disabled):active:focus, .brand-instagram:not(:disabled):not(.disabled).active:focus,
    .show > .brand-instagram.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(107, 146, 178, 0.5); }
  .brand-instagram:hover {
    box-shadow: 0 5px 10px rgba(81, 127, 164, 0.4) !important; }

.brand-lastfm {
  color: #fff;
  background-color: #c3000d;
  border-color: #c3000d; }
  .brand-lastfm:hover {
    color: #fff;
    background-color: #9d000a;
    border-color: #90000a; }
  .brand-lastfm:focus, .brand-lastfm.focus {
    color: #fff;
    background-color: #9d000a;
    border-color: #90000a;
    box-shadow: 0 0 0 0.2rem rgba(204, 38, 49, 0.5); }
  .brand-lastfm.disabled, .brand-lastfm:disabled {
    color: #fff;
    background-color: #c3000d;
    border-color: #c3000d; }
  .brand-lastfm:not(:disabled):not(.disabled):active, .brand-lastfm:not(:disabled):not(.disabled).active,
  .show > .brand-lastfm.dropdown-toggle {
    color: #fff;
    background-color: #90000a;
    border-color: #830009; }
    .brand-lastfm:not(:disabled):not(.disabled):active:focus, .brand-lastfm:not(:disabled):not(.disabled).active:focus,
    .show > .brand-lastfm.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(204, 38, 49, 0.5); }
  .brand-lastfm:hover {
    box-shadow: 0 5px 10px rgba(195, 0, 13, 0.4) !important; }

.brand-soundcloud {
  color: #212529;
  background-color: #ff8800;
  border-color: #ff8800; }
  .brand-soundcloud:hover {
    color: #fff;
    background-color: #d97400;
    border-color: #cc6d00; }
  .brand-soundcloud:focus, .brand-soundcloud.focus {
    color: #fff;
    background-color: #d97400;
    border-color: #cc6d00;
    box-shadow: 0 0 0 0.2rem rgba(222, 121, 6, 0.5); }
  .brand-soundcloud.disabled, .brand-soundcloud:disabled {
    color: #212529;
    background-color: #ff8800;
    border-color: #ff8800; }
  .brand-soundcloud:not(:disabled):not(.disabled):active, .brand-soundcloud:not(:disabled):not(.disabled).active,
  .show > .brand-soundcloud.dropdown-toggle {
    color: #fff;
    background-color: #cc6d00;
    border-color: #bf6600; }
    .brand-soundcloud:not(:disabled):not(.disabled):active:focus, .brand-soundcloud:not(:disabled):not(.disabled).active:focus,
    .show > .brand-soundcloud.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 121, 6, 0.5); }
  .brand-soundcloud:hover {
    box-shadow: 0 5px 10px rgba(255, 136, 0, 0.4) !important; }

.brand-behance {
  color: #fff;
  background-color: #1769ff;
  border-color: #1769ff; }
  .brand-behance:hover {
    color: #fff;
    background-color: #0055f0;
    border-color: #0050e3; }
  .brand-behance:focus, .brand-behance.focus {
    color: #fff;
    background-color: #0055f0;
    border-color: #0050e3;
    box-shadow: 0 0 0 0.2rem rgba(58, 128, 255, 0.5); }
  .brand-behance.disabled, .brand-behance:disabled {
    color: #fff;
    background-color: #1769ff;
    border-color: #1769ff; }
  .brand-behance:not(:disabled):not(.disabled):active, .brand-behance:not(:disabled):not(.disabled).active,
  .show > .brand-behance.dropdown-toggle {
    color: #fff;
    background-color: #0050e3;
    border-color: #004cd6; }
    .brand-behance:not(:disabled):not(.disabled):active:focus, .brand-behance:not(:disabled):not(.disabled).active:focus,
    .show > .brand-behance.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 128, 255, 0.5); }
  .brand-behance:hover {
    box-shadow: 0 5px 10px rgba(23, 105, 255, 0.4) !important; }

.brand-envato {
  color: #212529;
  background-color: #82b541;
  border-color: #82b541; }
  .brand-envato:hover {
    color: #fff;
    background-color: #6e9937;
    border-color: #678f34; }
  .brand-envato:focus, .brand-envato.focus {
    color: #fff;
    background-color: #6e9937;
    border-color: #678f34;
    box-shadow: 0 0 0 0.2rem rgba(115, 159, 61, 0.5); }
  .brand-envato.disabled, .brand-envato:disabled {
    color: #212529;
    background-color: #82b541;
    border-color: #82b541; }
  .brand-envato:not(:disabled):not(.disabled):active, .brand-envato:not(:disabled):not(.disabled).active,
  .show > .brand-envato.dropdown-toggle {
    color: #fff;
    background-color: #678f34;
    border-color: #608630; }
    .brand-envato:not(:disabled):not(.disabled):active:focus, .brand-envato:not(:disabled):not(.disabled).active:focus,
    .show > .brand-envato.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(115, 159, 61, 0.5); }
  .brand-envato:hover {
    box-shadow: 0 5px 10px rgba(130, 181, 65, 0.4) !important; }

.brand-medium {
  color: #fff;
  background-color: #00ab6c;
  border-color: #00ab6c; }
  .brand-medium:hover {
    color: #fff;
    background-color: #008554;
    border-color: #00784c; }
  .brand-medium:focus, .brand-medium.focus {
    color: #fff;
    background-color: #008554;
    border-color: #00784c;
    box-shadow: 0 0 0 0.2rem rgba(38, 184, 130, 0.5); }
  .brand-medium.disabled, .brand-medium:disabled {
    color: #fff;
    background-color: #00ab6c;
    border-color: #00ab6c; }
  .brand-medium:not(:disabled):not(.disabled):active, .brand-medium:not(:disabled):not(.disabled).active,
  .show > .brand-medium.dropdown-toggle {
    color: #fff;
    background-color: #00784c;
    border-color: #006b44; }
    .brand-medium:not(:disabled):not(.disabled):active:focus, .brand-medium:not(:disabled):not(.disabled).active:focus,
    .show > .brand-medium.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 184, 130, 0.5); }
  .brand-medium:hover {
    box-shadow: 0 5px 10px rgba(0, 171, 108, 0.4) !important; }

.brand-spotify {
  color: #fff;
  background-color: #1db954;
  border-color: #1db954; }
  .brand-spotify:hover {
    color: #fff;
    background-color: #189845;
    border-color: #168d40; }
  .brand-spotify:focus, .brand-spotify.focus {
    color: #fff;
    background-color: #189845;
    border-color: #168d40;
    box-shadow: 0 0 0 0.2rem rgba(63, 196, 110, 0.5); }
  .brand-spotify.disabled, .brand-spotify:disabled {
    color: #fff;
    background-color: #1db954;
    border-color: #1db954; }
  .brand-spotify:not(:disabled):not(.disabled):active, .brand-spotify:not(:disabled):not(.disabled).active,
  .show > .brand-spotify.dropdown-toggle {
    color: #fff;
    background-color: #168d40;
    border-color: #14823b; }
    .brand-spotify:not(:disabled):not(.disabled):active:focus, .brand-spotify:not(:disabled):not(.disabled).active:focus,
    .show > .brand-spotify.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(63, 196, 110, 0.5); }
  .brand-spotify:hover {
    box-shadow: 0 5px 10px rgba(29, 185, 84, 0.4) !important; }

.brand-quora {
  color: #fff;
  background-color: #a82400;
  border-color: #a82400; }
  .brand-quora:hover {
    color: #fff;
    background-color: #821c00;
    border-color: #751900; }
  .brand-quora:focus, .brand-quora.focus {
    color: #fff;
    background-color: #821c00;
    border-color: #751900;
    box-shadow: 0 0 0 0.2rem rgba(181, 69, 38, 0.5); }
  .brand-quora.disabled, .brand-quora:disabled {
    color: #fff;
    background-color: #a82400;
    border-color: #a82400; }
  .brand-quora:not(:disabled):not(.disabled):active, .brand-quora:not(:disabled):not(.disabled).active,
  .show > .brand-quora.dropdown-toggle {
    color: #fff;
    background-color: #751900;
    border-color: #681600; }
    .brand-quora:not(:disabled):not(.disabled):active:focus, .brand-quora:not(:disabled):not(.disabled).active:focus,
    .show > .brand-quora.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(181, 69, 38, 0.5); }
  .brand-quora:hover {
    box-shadow: 0 5px 10px rgba(168, 36, 0, 0.4) !important; }

.brand-xing {
  color: #fff;
  background-color: #026466;
  border-color: #026466; }
  .brand-xing:hover {
    color: #fff;
    background-color: #013f40;
    border-color: #013334; }
  .brand-xing:focus, .brand-xing.focus {
    color: #fff;
    background-color: #013f40;
    border-color: #013334;
    box-shadow: 0 0 0 0.2rem rgba(40, 123, 125, 0.5); }
  .brand-xing.disabled, .brand-xing:disabled {
    color: #fff;
    background-color: #026466;
    border-color: #026466; }
  .brand-xing:not(:disabled):not(.disabled):active, .brand-xing:not(:disabled):not(.disabled).active,
  .show > .brand-xing.dropdown-toggle {
    color: #fff;
    background-color: #013334;
    border-color: #012727; }
    .brand-xing:not(:disabled):not(.disabled):active:focus, .brand-xing:not(:disabled):not(.disabled).active:focus,
    .show > .brand-xing.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 123, 125, 0.5); }
  .brand-xing:hover {
    box-shadow: 0 5px 10px rgba(2, 100, 102, 0.4) !important; }

.brand-snapchat {
  color: #212529;
  background-color: #fffc00;
  border-color: #fffc00; }
  .brand-snapchat:hover {
    color: #212529;
    background-color: #d9d600;
    border-color: #ccca00; }
  .brand-snapchat:focus, .brand-snapchat.focus {
    color: #212529;
    background-color: #d9d600;
    border-color: #ccca00;
    box-shadow: 0 0 0 0.2rem rgba(222, 220, 6, 0.5); }
  .brand-snapchat.disabled, .brand-snapchat:disabled {
    color: #212529;
    background-color: #fffc00;
    border-color: #fffc00; }
  .brand-snapchat:not(:disabled):not(.disabled):active, .brand-snapchat:not(:disabled):not(.disabled).active,
  .show > .brand-snapchat.dropdown-toggle {
    color: #212529;
    background-color: #ccca00;
    border-color: #bfbd00; }
    .brand-snapchat:not(:disabled):not(.disabled):active:focus, .brand-snapchat:not(:disabled):not(.disabled).active:focus,
    .show > .brand-snapchat.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 220, 6, 0.5); }
  .brand-snapchat:hover {
    box-shadow: 0 5px 10px rgba(255, 252, 0, 0.4) !important; }

.brand-telegram {
  color: #fff;
  background-color: #0088cc;
  border-color: #0088cc; }
  .brand-telegram:hover {
    color: #fff;
    background-color: #006fa6;
    border-color: #006699; }
  .brand-telegram:focus, .brand-telegram.focus {
    color: #fff;
    background-color: #006fa6;
    border-color: #006699;
    box-shadow: 0 0 0 0.2rem rgba(38, 154, 212, 0.5); }
  .brand-telegram.disabled, .brand-telegram:disabled {
    color: #fff;
    background-color: #0088cc;
    border-color: #0088cc; }
  .brand-telegram:not(:disabled):not(.disabled):active, .brand-telegram:not(:disabled):not(.disabled).active,
  .show > .brand-telegram.dropdown-toggle {
    color: #fff;
    background-color: #006699;
    border-color: #005e8c; }
    .brand-telegram:not(:disabled):not(.disabled):active:focus, .brand-telegram:not(:disabled):not(.disabled).active:focus,
    .show > .brand-telegram.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 154, 212, 0.5); }
  .brand-telegram:hover {
    box-shadow: 0 5px 10px rgba(0, 136, 204, 0.4) !important; }

.brand-whatsapp {
  color: #fff;
  background-color: #075e54;
  border-color: #075e54; }
  .brand-whatsapp:hover {
    color: #fff;
    background-color: #043a34;
    border-color: #032f2a; }
  .brand-whatsapp:focus, .brand-whatsapp.focus {
    color: #fff;
    background-color: #043a34;
    border-color: #032f2a;
    box-shadow: 0 0 0 0.2rem rgba(44, 118, 110, 0.5); }
  .brand-whatsapp.disabled, .brand-whatsapp:disabled {
    color: #fff;
    background-color: #075e54;
    border-color: #075e54; }
  .brand-whatsapp:not(:disabled):not(.disabled):active, .brand-whatsapp:not(:disabled):not(.disabled).active,
  .show > .brand-whatsapp.dropdown-toggle {
    color: #fff;
    background-color: #032f2a;
    border-color: #03231f; }
    .brand-whatsapp:not(:disabled):not(.disabled):active:focus, .brand-whatsapp:not(:disabled):not(.disabled).active:focus,
    .show > .brand-whatsapp.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(44, 118, 110, 0.5); }
  .brand-whatsapp:hover {
    box-shadow: 0 5px 10px rgba(7, 94, 84, 0.4) !important; }

.brand-rss {
  color: #fff;
  background-color: #f26522;
  border-color: #f26522; }
  .brand-rss:hover {
    color: #fff;
    background-color: #e1510d;
    border-color: #d54d0d; }
  .brand-rss:focus, .brand-rss.focus {
    color: #fff;
    background-color: #e1510d;
    border-color: #d54d0d;
    box-shadow: 0 0 0 0.2rem rgba(244, 124, 67, 0.5); }
  .brand-rss.disabled, .brand-rss:disabled {
    color: #fff;
    background-color: #f26522;
    border-color: #f26522; }
  .brand-rss:not(:disabled):not(.disabled):active, .brand-rss:not(:disabled):not(.disabled).active,
  .show > .brand-rss.dropdown-toggle {
    color: #fff;
    background-color: #d54d0d;
    border-color: #c8490c; }
    .brand-rss:not(:disabled):not(.disabled):active:focus, .brand-rss:not(:disabled):not(.disabled).active:focus,
    .show > .brand-rss.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(244, 124, 67, 0.5); }
  .brand-rss:hover {
    box-shadow: 0 5px 10px rgba(242, 101, 34, 0.4) !important; }

/* 2.3 Devices ======================================================= */
.iphone, .iphone-x, .ipad {
  border: 1px solid #edf4f8;
  margin: 0 auto;
  position: relative; }
  .iphone:before, .iphone:after, .iphone-x:before, .iphone-x:after, .ipad:before, .ipad:after {
    content: '';
    position: absolute; }
  .iphone:before, .iphone-x:before, .ipad:before {
    width: 45px;
    height: 4px;
    left: 0;
    right: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    border-radius: 30px; }
  .iphone:after, .iphone-x:after, .ipad:after {
    left: 50%;
    width: 8px;
    height: 8px;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.03);
    display: block;
    border-radius: 50%; }
  .iphone .screen, .iphone-x .screen, .ipad .screen {
    margin: 0 auto; }
    .iphone .screen img, .iphone-x .screen img, .ipad .screen img {
      max-width: 100%; }

.iphone, .ipad {
  background: #fff;
  max-width: 255px;
  border-radius: 36px;
  padding: 55px 0;
  box-shadow: inset 0 4px 10px 1px #fff, inset 0 0 6px 0 rgba(66, 77, 86, 0.5), 0 2px 0 #aeb5bb, 0 20px 50px -25px rgba(0, 0, 0, 0.5);
  /* speaker */
  /* camera */ }
  .iphone:before, .ipad:before {
    background: #e3e8eb;
    margin: -25px auto 0; }
  .iphone:after, .ipad:after {
    top: 30px;
    margin-left: -45px;
    background: #e3e8eb; }
  .iphone .screen, .ipad .screen {
    width: 90%; }
    .iphone .screen img, .ipad .screen img {
      box-shadow: 0 0 0 1px rgba(0, 17, 53, 0.16); }
  .iphone .button, .ipad .button {
    box-shadow: 0 0 1px 2px #e3e8eb inset;
    border: 1px solid #edf4f8;
    border-radius: 50%;
    bottom: 1.75%;
    height: 0;
    padding-top: 36px;
    width: 36px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
  .iphone.small, .ipad.small {
    max-width: 185px;
    border-radius: 26px;
    padding: 45px 0; }
    .iphone.small:before, .ipad.small:before {
      width: 35px; }
    .iphone.small:after, .ipad.small:after {
      top: 20px; }
    .iphone.small .button, .ipad.small .button {
      width: 28px;
      padding-top: 28px;
      margin-left: -8px; }
  .iphone.landscape, .ipad.landscape {
    padding: 1rem 3rem; }
    .iphone.landscape:before, .iphone.landscape:after, .ipad.landscape:before, .ipad.landscape:after {
      left: 16%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
    .iphone.landscape .button, .ipad.landscape .button {
      left: 100%;
      margin-left: -50px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }

.ipad:before {
  content: none; }

.ipad.portrait:after {
  margin-left: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }

.iphone-x {
  background: #181715;
  max-width: 243.75px;
  border-radius: 26px;
  padding: 9.75px;
  box-shadow: 0 1px 0 #aeb5bb, 0 20px 50px -25px rgba(0, 0, 0, 0.5);
  /* speaker */
  /* camera */ }
  .iphone-x:before, .iphone-x:after {
    background-color: #292724;
    z-index: 1; }
  .iphone-x:before {
    margin: 0 auto; }
  .iphone-x:after {
    top: 8px;
    margin-left: 33px; }
  .iphone-x .screen {
    border-radius: 13px;
    overflow: hidden; }
    .iphone-x .screen img {
      box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.015); }
  .iphone-x .notch {
    position: absolute;
    border-radius: 13px;
    width: 135.85px;
    height: 22.75px;
    transform: translateX(-50%);
    background-color: #181715;
    top: 0;
    left: 50%; }
  .iphone-x.light {
    background-color: #fff; }
    .iphone-x.light .notch {
      background-color: #fff; }
    .iphone-x.light:before, .iphone-x.light:after {
      background-color: #e3e8eb; }

.device-twin {
  width: 100%;
  display: flex;
  position: relative; }
  .device-twin .mockup {
    box-shadow: inset 0 4px 10px 1px #ffffff, inset 0 0 6px 0 rgba(66, 77, 86, 0.5), 0 20px 50px -25px rgba(0, 0, 0, 0.5), 0 0 1px rgba(0, 0, 0, 0.1);
    max-width: 210px; }
  @media (min-width: 768px) {
    .device-twin .phone {
      transition: all .4s ease; }
      .device-twin .phone.front:hover {
        transform: rotate(2deg) translate3d(0, -8px, 0); }
      .device-twin .phone.absolute {
        margin-top: -30px; }
        .device-twin .phone.absolute:hover {
          transform: rotate(-2deg) translate3d(-10px, 0, 0); } }

.browser {
  background: linear-gradient(#fff 1%, #eef1f3 10%);
  position: relative;
  padding-top: 2em;
  border-radius: .4em; }
  .browser:before {
    display: block;
    position: absolute;
    content: '';
    top: 0.75em;
    left: 1em;
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    background: #f44;
    box-shadow: 0 0 0 1px #f44, 1.5em 0 0 1px #9b3, 3em 0 0 1px #fb5; }
  .browser img {
    max-width: 100%; }
  .browser.shadow {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.09); }
  .browser.border {
    border: 1px solid #edf4f8; }

.fade-bottom:after {
  background: linear-gradient(rgba(255, 255, 255, 0), #fff 70%, #fff);
  bottom: -25px;
  content: "";
  display: block;
  height: 85px;
  left: 0;
  position: absolute;
  right: 0; }

.gradient {
  background-color: transparent; }
  .gradient.gradient-primary-light {
    background-image: linear-gradient(45deg, #ff6f61 0, #ff8c7c 100%); }
  .gradient.gradient-primary-dark {
    background-image: linear-gradient(45deg, #ff6f61 0, #e56357 100%); }
  .gradient.gradient-primary-secondary {
    background-image: linear-gradient(45deg, #ff6f61 0, #fc9e21 100%); }
  .gradient.gradient-primary-alternate {
    background-image: linear-gradient(45deg, #ff6f61 0, #007cb7 100%); }
  .gradient.gradient-secondary-light {
    background-image: linear-gradient(45deg, #fc9e21 0, #009cf7 100%); }
  .gradient.gradient-secondary-dark {
    background-image: linear-gradient(45deg, #fc9e21 0, #01729a 100%); }
  .gradient.gradient-alternate-light {
    background-image: linear-gradient(45deg, #007cb7 0, #009cf7 100%); }
  .gradient.gradient-alternate-dark {
    background-image: linear-gradient(45deg, #007cb7 0, #01729a 100%); }
  .gradient.gradient-success-light {
    background-image: linear-gradient(45deg, #37bd74 0, #50c783 100%); }
  .gradient.gradient-success-dark {
    background-image: linear-gradient(45deg, #37bd74 0, #2eaa66 100%); }
  .gradient.gradient-info-light {
    background-image: linear-gradient(45deg, #11cdef 0, #42d2f1 100%); }
  .gradient.gradient-info-dark {
    background-image: linear-gradient(45deg, #11cdef 0, #0fbad8 100%); }
  .gradient.gradient-warning-light {
    background-image: linear-gradient(45deg, #dc793e 0, #ffa73c 100%); }
  .gradient.gradient-warning-dark {
    background-image: linear-gradient(45deg, #dc793e 0, #ec8c01 100%); }
  .gradient.gradient-danger-light {
    background-image: linear-gradient(45deg, #f44236 0, #f75446 100%); }
  .gradient.gradient-danger-dark {
    background-image: linear-gradient(45deg, #f44236 0, #de392e 100%); }
  .gradient.gradient-dark-light {
    background-image: linear-gradient(45deg, #181715 0, #595752 100%); }
  .gradient.gradient-dark-dark {
    background-image: linear-gradient(45deg, #181715 0, #3f3e39 100%); }
  .gradient.gradient-light-light {
    background-image: linear-gradient(45deg, #edf4f8 0, #595752 100%); }
  .gradient.gradient-light-dark {
    background-image: linear-gradient(45deg, #edf4f8 0, #3f3e39 100%); }
  .gradient.gradient-primary-auxiliary {
    background-image: linear-gradient(45deg, #fe2983 0, #ff6f61 50%, #fc9e21 110%); }
  .gradient.card .card-header {
    background-color: transparent; }

.icon {
  margin-left: 5px;
  margin-right: 15px;
  padding: 0;
  display: inline-block;
  text-align: center; }
  .icon.logo {
    max-width: 80px; }
  .icon i {
    margin: 0; }

img.icon {
  max-width: 54px; }

.icon-sm {
  width: 0.85rem;
  height: 0.85rem; }

.icon-md {
  width: 2rem;
  height: 2rem; }

.icon-lg {
  width: 3.25rem;
  height: 3.25rem; }

.icon-xl {
  width: 4.5rem;
  height: 4.5rem; }

.icon-xxl {
  width: 6.5rem;
  height: 6.5rem; }

.list-icon {
  list-style-type: none; }
  .list-icon li > * {
    display: table-cell; }
  .list-icon li i {
    padding-right: 15px; }

.icon-block {
  padding: 15px;
  margin-bottom: 15px; }
  .icon-block p {
    margin-top: 10px; }

.icon-box, .icon-shape {
  display: flex;
  align-items: center;
  justify-content: center; }

.icon-box {
  padding: 1rem; }

.icon-shape {
  position: relative;
  flex-direction: column; }
  .icon-shape .icon {
    margin: 0;
    position: absolute;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0); }

.more-link {
  margin-top: 2rem; }
  .more-link .icon {
    margin-left: 8px;
    transform: translateX(0);
    transition: transform 0.3s ease-out; }
  .more-link:hover .icon {
    transform: translateX(5px); }

/* 2.5 Misc ======================================================= */
blockquote {
  position: relative; }
  blockquote:before {
    font-family: "Font Awesome 5 Free";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-weight: 900;
    content: "\f10d";
    opacity: 0.5;
    font-size: 2rem;
    position: absolute;
    left: -16px;
    top: -16px; }
  blockquote.team-quote:before {
    left: 0;
    top: -8px; }

.fullscreen {
  min-height: 100vh; }

@media (min-width: 576px) {
  .fullscreen-sm {
    min-height: 100vh; } }

@media (min-width: 768px) {
  .fullscreen-md {
    min-height: 100vh; } }

@media (min-width: 992px) {
  .fullscreen-lg {
    min-height: 100vh; } }

@media (min-width: 1200px) {
  .fullscreen-xl {
    min-height: 100vh; } }

.overflow-hidden {
  overflow: hidden; }

.overflow-clear {
  overflow: unset; }

.overflow-x {
  overflow-x: auto; }

.overflow-x-hidden {
  overflow-x: hidden; }

.overflow-x-clear {
  overflow-x: unset; }

.overflow-y {
  overflow-y: auto; }

.overflow-y-hidden {
  overflow-y: hidden; }

.overflow-y-clear {
  overflow-y: unset; }

.top {
  top: 0; }

.right {
  right: 0; }

.bottom {
  bottom: 0; }

.left {
  left: 0; }

.op-1 {
  opacity: 0.1; }

.w-10 {
  width: 10%; }

.op-2 {
  opacity: 0.2; }

.w-20 {
  width: 20%; }

.op-3 {
  opacity: 0.3; }

.w-30 {
  width: 30%; }

.op-4 {
  opacity: 0.4; }

.w-40 {
  width: 40%; }

.op-5 {
  opacity: 0.5; }

.w-50 {
  width: 50%; }

.op-6 {
  opacity: 0.6; }

.w-60 {
  width: 60%; }

.op-7 {
  opacity: 0.7; }

.w-70 {
  width: 70%; }

.op-8 {
  opacity: 0.8; }

.w-80 {
  width: 80%; }

.op-9 {
  opacity: 0.9; }

.w-90 {
  width: 90%; }

/* 5.2 Mockups ======================================================= */
.mockup-wrapper {
  position: relative; }

.mockup {
  position: relative;
  margin: 0 auto;
  max-width: 255px; }
  .mockup img {
    display: block;
    max-width: 100%;
    height: auto; }

/* 2.6 Overlay ======================================================= */
.overlay {
  position: relative; }
  .overlay:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(39, 51, 62, 0.6);
    opacity: 1;
    z-index: 0; }
  .overlay [class^='container'],
  .overlay > .content {
    position: relative;
    z-index: 1; }
  .overlay > .content {
    position: relative; }
  .overlay.overlay-primary:after {
    background-color: rgba(255, 111, 97, 0.6); }
  .overlay.overlay-primary.alpha-1:after {
    background-color: rgba(255, 111, 97, 0.1); }
  .overlay.overlay-primary.alpha-2:after {
    background-color: rgba(255, 111, 97, 0.2); }
  .overlay.overlay-primary.alpha-3:after {
    background-color: rgba(255, 111, 97, 0.3); }
  .overlay.overlay-primary.alpha-4:after {
    background-color: rgba(255, 111, 97, 0.4); }
  .overlay.overlay-primary.alpha-5:after {
    background-color: rgba(255, 111, 97, 0.5); }
  .overlay.overlay-primary.alpha-6:after {
    background-color: rgba(255, 111, 97, 0.6); }
  .overlay.overlay-primary.alpha-7:after {
    background-color: rgba(255, 111, 97, 0.7); }
  .overlay.overlay-primary.alpha-8:after {
    background-color: rgba(255, 111, 97, 0.8); }
  .overlay.overlay-primary.alpha-9:after {
    background-color: rgba(255, 111, 97, 0.9); }
  .overlay.overlay-secondary:after {
    background-color: rgba(252, 158, 33, 0.6); }
  .overlay.overlay-secondary.alpha-1:after {
    background-color: rgba(252, 158, 33, 0.1); }
  .overlay.overlay-secondary.alpha-2:after {
    background-color: rgba(252, 158, 33, 0.2); }
  .overlay.overlay-secondary.alpha-3:after {
    background-color: rgba(252, 158, 33, 0.3); }
  .overlay.overlay-secondary.alpha-4:after {
    background-color: rgba(252, 158, 33, 0.4); }
  .overlay.overlay-secondary.alpha-5:after {
    background-color: rgba(252, 158, 33, 0.5); }
  .overlay.overlay-secondary.alpha-6:after {
    background-color: rgba(252, 158, 33, 0.6); }
  .overlay.overlay-secondary.alpha-7:after {
    background-color: rgba(252, 158, 33, 0.7); }
  .overlay.overlay-secondary.alpha-8:after {
    background-color: rgba(252, 158, 33, 0.8); }
  .overlay.overlay-secondary.alpha-9:after {
    background-color: rgba(252, 158, 33, 0.9); }
  .overlay.overlay-success:after {
    background-color: rgba(55, 189, 116, 0.6); }
  .overlay.overlay-success.alpha-1:after {
    background-color: rgba(55, 189, 116, 0.1); }
  .overlay.overlay-success.alpha-2:after {
    background-color: rgba(55, 189, 116, 0.2); }
  .overlay.overlay-success.alpha-3:after {
    background-color: rgba(55, 189, 116, 0.3); }
  .overlay.overlay-success.alpha-4:after {
    background-color: rgba(55, 189, 116, 0.4); }
  .overlay.overlay-success.alpha-5:after {
    background-color: rgba(55, 189, 116, 0.5); }
  .overlay.overlay-success.alpha-6:after {
    background-color: rgba(55, 189, 116, 0.6); }
  .overlay.overlay-success.alpha-7:after {
    background-color: rgba(55, 189, 116, 0.7); }
  .overlay.overlay-success.alpha-8:after {
    background-color: rgba(55, 189, 116, 0.8); }
  .overlay.overlay-success.alpha-9:after {
    background-color: rgba(55, 189, 116, 0.9); }
  .overlay.overlay-info:after {
    background-color: rgba(17, 205, 239, 0.6); }
  .overlay.overlay-info.alpha-1:after {
    background-color: rgba(17, 205, 239, 0.1); }
  .overlay.overlay-info.alpha-2:after {
    background-color: rgba(17, 205, 239, 0.2); }
  .overlay.overlay-info.alpha-3:after {
    background-color: rgba(17, 205, 239, 0.3); }
  .overlay.overlay-info.alpha-4:after {
    background-color: rgba(17, 205, 239, 0.4); }
  .overlay.overlay-info.alpha-5:after {
    background-color: rgba(17, 205, 239, 0.5); }
  .overlay.overlay-info.alpha-6:after {
    background-color: rgba(17, 205, 239, 0.6); }
  .overlay.overlay-info.alpha-7:after {
    background-color: rgba(17, 205, 239, 0.7); }
  .overlay.overlay-info.alpha-8:after {
    background-color: rgba(17, 205, 239, 0.8); }
  .overlay.overlay-info.alpha-9:after {
    background-color: rgba(17, 205, 239, 0.9); }
  .overlay.overlay-warning:after {
    background-color: rgba(220, 121, 62, 0.6); }
  .overlay.overlay-warning.alpha-1:after {
    background-color: rgba(220, 121, 62, 0.1); }
  .overlay.overlay-warning.alpha-2:after {
    background-color: rgba(220, 121, 62, 0.2); }
  .overlay.overlay-warning.alpha-3:after {
    background-color: rgba(220, 121, 62, 0.3); }
  .overlay.overlay-warning.alpha-4:after {
    background-color: rgba(220, 121, 62, 0.4); }
  .overlay.overlay-warning.alpha-5:after {
    background-color: rgba(220, 121, 62, 0.5); }
  .overlay.overlay-warning.alpha-6:after {
    background-color: rgba(220, 121, 62, 0.6); }
  .overlay.overlay-warning.alpha-7:after {
    background-color: rgba(220, 121, 62, 0.7); }
  .overlay.overlay-warning.alpha-8:after {
    background-color: rgba(220, 121, 62, 0.8); }
  .overlay.overlay-warning.alpha-9:after {
    background-color: rgba(220, 121, 62, 0.9); }
  .overlay.overlay-danger:after {
    background-color: rgba(244, 66, 54, 0.6); }
  .overlay.overlay-danger.alpha-1:after {
    background-color: rgba(244, 66, 54, 0.1); }
  .overlay.overlay-danger.alpha-2:after {
    background-color: rgba(244, 66, 54, 0.2); }
  .overlay.overlay-danger.alpha-3:after {
    background-color: rgba(244, 66, 54, 0.3); }
  .overlay.overlay-danger.alpha-4:after {
    background-color: rgba(244, 66, 54, 0.4); }
  .overlay.overlay-danger.alpha-5:after {
    background-color: rgba(244, 66, 54, 0.5); }
  .overlay.overlay-danger.alpha-6:after {
    background-color: rgba(244, 66, 54, 0.6); }
  .overlay.overlay-danger.alpha-7:after {
    background-color: rgba(244, 66, 54, 0.7); }
  .overlay.overlay-danger.alpha-8:after {
    background-color: rgba(244, 66, 54, 0.8); }
  .overlay.overlay-danger.alpha-9:after {
    background-color: rgba(244, 66, 54, 0.9); }
  .overlay.overlay-light:after {
    background-color: rgba(237, 244, 248, 0.6); }
  .overlay.overlay-light.alpha-1:after {
    background-color: rgba(237, 244, 248, 0.1); }
  .overlay.overlay-light.alpha-2:after {
    background-color: rgba(237, 244, 248, 0.2); }
  .overlay.overlay-light.alpha-3:after {
    background-color: rgba(237, 244, 248, 0.3); }
  .overlay.overlay-light.alpha-4:after {
    background-color: rgba(237, 244, 248, 0.4); }
  .overlay.overlay-light.alpha-5:after {
    background-color: rgba(237, 244, 248, 0.5); }
  .overlay.overlay-light.alpha-6:after {
    background-color: rgba(237, 244, 248, 0.6); }
  .overlay.overlay-light.alpha-7:after {
    background-color: rgba(237, 244, 248, 0.7); }
  .overlay.overlay-light.alpha-8:after {
    background-color: rgba(237, 244, 248, 0.8); }
  .overlay.overlay-light.alpha-9:after {
    background-color: rgba(237, 244, 248, 0.9); }
  .overlay.overlay-dark:after {
    background-color: rgba(24, 23, 21, 0.6); }
  .overlay.overlay-dark.alpha-1:after {
    background-color: rgba(24, 23, 21, 0.1); }
  .overlay.overlay-dark.alpha-2:after {
    background-color: rgba(24, 23, 21, 0.2); }
  .overlay.overlay-dark.alpha-3:after {
    background-color: rgba(24, 23, 21, 0.3); }
  .overlay.overlay-dark.alpha-4:after {
    background-color: rgba(24, 23, 21, 0.4); }
  .overlay.overlay-dark.alpha-5:after {
    background-color: rgba(24, 23, 21, 0.5); }
  .overlay.overlay-dark.alpha-6:after {
    background-color: rgba(24, 23, 21, 0.6); }
  .overlay.overlay-dark.alpha-7:after {
    background-color: rgba(24, 23, 21, 0.7); }
  .overlay.overlay-dark.alpha-8:after {
    background-color: rgba(24, 23, 21, 0.8); }
  .overlay.overlay-dark.alpha-9:after {
    background-color: rgba(24, 23, 21, 0.9); }
  .overlay.overlay-alternate:after {
    background-color: rgba(0, 124, 183, 0.6); }
  .overlay.overlay-alternate.alpha-1:after {
    background-color: rgba(0, 124, 183, 0.1); }
  .overlay.overlay-alternate.alpha-2:after {
    background-color: rgba(0, 124, 183, 0.2); }
  .overlay.overlay-alternate.alpha-3:after {
    background-color: rgba(0, 124, 183, 0.3); }
  .overlay.overlay-alternate.alpha-4:after {
    background-color: rgba(0, 124, 183, 0.4); }
  .overlay.overlay-alternate.alpha-5:after {
    background-color: rgba(0, 124, 183, 0.5); }
  .overlay.overlay-alternate.alpha-6:after {
    background-color: rgba(0, 124, 183, 0.6); }
  .overlay.overlay-alternate.alpha-7:after {
    background-color: rgba(0, 124, 183, 0.7); }
  .overlay.overlay-alternate.alpha-8:after {
    background-color: rgba(0, 124, 183, 0.8); }
  .overlay.overlay-alternate.alpha-9:after {
    background-color: rgba(0, 124, 183, 0.9); }
  .overlay.overlay-contrast:after {
    background-color: rgba(255, 255, 255, 0.6); }
  .overlay.overlay-contrast.alpha-1:after {
    background-color: rgba(255, 255, 255, 0.1); }
  .overlay.overlay-contrast.alpha-2:after {
    background-color: rgba(255, 255, 255, 0.2); }
  .overlay.overlay-contrast.alpha-3:after {
    background-color: rgba(255, 255, 255, 0.3); }
  .overlay.overlay-contrast.alpha-4:after {
    background-color: rgba(255, 255, 255, 0.4); }
  .overlay.overlay-contrast.alpha-5:after {
    background-color: rgba(255, 255, 255, 0.5); }
  .overlay.overlay-contrast.alpha-6:after {
    background-color: rgba(255, 255, 255, 0.6); }
  .overlay.overlay-contrast.alpha-7:after {
    background-color: rgba(255, 255, 255, 0.7); }
  .overlay.overlay-contrast.alpha-8:after {
    background-color: rgba(255, 255, 255, 0.8); }
  .overlay.overlay-contrast.alpha-9:after {
    background-color: rgba(255, 255, 255, 0.9); }

.inner-overlay {
  position: absolute;
  top: -100px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1; }

/* 2.7 Position ======================================================= */
.relative {
  position: relative !important; }

.absolute {
  position: absolute !important; }

.center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }

.center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.center-xy {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0); }

@media (min-width: 576px) {
  .absolute-sm {
    position: absolute !important; } }

@media (min-width: 576px) {
  .center-x-sm {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 576px) {
  .center-y-sm {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); } }

@media (min-width: 576px) {
  .center-xy-sm {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0); } }

@media (min-width: 768px) {
  .absolute-md {
    position: absolute !important; } }

@media (min-width: 768px) {
  .center-x-md {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 768px) {
  .center-y-md {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); } }

@media (min-width: 768px) {
  .center-xy-md {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0); } }

@media (min-width: 992px) {
  .absolute-lg {
    position: absolute !important; } }

@media (min-width: 992px) {
  .center-x-lg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 992px) {
  .center-y-lg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); } }

@media (min-width: 992px) {
  .center-xy-lg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0); } }

@media (min-width: 1200px) {
  .absolute-xl {
    position: absolute !important; } }

@media (min-width: 1200px) {
  .center-x-xl {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 1200px) {
  .center-y-xl {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); } }

@media (min-width: 1200px) {
  .center-xy-xl {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0); } }

.top {
  top: 0 !important; }

.right {
  right: 0 !important; }

.bottom {
  bottom: 0 !important; }

.left {
  left: 0 !important; }

.tx-50 {
  transform: translateX(50%); }

.ty-50 {
  transform: translateY(50%); }

/* 2.8 Responsive ======================================================= */
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto; }

.row.gap-y {
  margin-top: -15px;
  margin-bottom: -15px; }
  .row.gap-y > .col,
  .row.gap-y > [class*="col-"] {
    padding-top: 15px;
    padding-bottom: 15px; }

.row.gap-2x {
  margin: -30px;
  margin: -30px; }
  .row.gap-2x > .col,
  .row.gap-2x > [class*="col-"] {
    padding: 30px;
    padding: 30px; }

@media (max-width: 991.98px) {
  .hide-mobile-overflow {
    overflow: hidden; } }

.shapes-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.shape-divider svg.shape-waves {
  height: 100px; }
  @media (min-width: 768px) {
    .shape-divider svg.shape-waves {
      height: 133px; } }

.background-shape-main {
  background-color: #ff6f61;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 120px;
  z-index: -1; }

.transparentize {
  opacity: 0.035; }

/* 2.9 Spacing ======================================================= */
.m-6 {
  margin: 6rem !important; }

.mt-6 {
  margin-top: 6rem !important; }

.mr-6 {
  margin-right: 6rem !important; }

.mb-6 {
  margin-bottom: 6rem !important; }

.ml-6 {
  margin-left: 6rem !important; }

.mx-6 {
  margin-right: 6rem !important;
  margin-left: 6rem !important; }

.my-6 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important; }

.m-7 {
  margin: 7rem !important; }

.mt-7 {
  margin-top: 7rem !important; }

.mr-7 {
  margin-right: 7rem !important; }

.mb-7 {
  margin-bottom: 7rem !important; }

.ml-7 {
  margin-left: 7rem !important; }

.mx-7 {
  margin-right: 7rem !important;
  margin-left: 7rem !important; }

.my-7 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important; }

.m-8 {
  margin: 8rem !important; }

.mt-8 {
  margin-top: 8rem !important; }

.mr-8 {
  margin-right: 8rem !important; }

.mb-8 {
  margin-bottom: 8rem !important; }

.ml-8 {
  margin-left: 8rem !important; }

.mx-8 {
  margin-right: 8rem !important;
  margin-left: 8rem !important; }

.my-8 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important; }

.m-9 {
  margin: 9rem !important; }

.mt-9 {
  margin-top: 9rem !important; }

.mr-9 {
  margin-right: 9rem !important; }

.mb-9 {
  margin-bottom: 9rem !important; }

.ml-9 {
  margin-left: 9rem !important; }

.mx-9 {
  margin-right: 9rem !important;
  margin-left: 9rem !important; }

.my-9 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important; }

.p-6 {
  padding: 6rem !important; }

.pt-6 {
  padding-top: 6rem !important; }

.pr-6 {
  padding-right: 6rem !important; }

.pb-6 {
  padding-bottom: 6rem !important; }

.pl-6 {
  padding-left: 6rem !important; }

.px-6 {
  padding-right: 6rem !important;
  padding-left: 6rem !important; }

.py-6 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important; }

.p-7 {
  padding: 7rem !important; }

.pt-7 {
  padding-top: 7rem !important; }

.pr-7 {
  padding-right: 7rem !important; }

.pb-7 {
  padding-bottom: 7rem !important; }

.pl-7 {
  padding-left: 7rem !important; }

.px-7 {
  padding-right: 7rem !important;
  padding-left: 7rem !important; }

.py-7 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important; }

.p-8 {
  padding: 8rem !important; }

.pt-8 {
  padding-top: 8rem !important; }

.pr-8 {
  padding-right: 8rem !important; }

.pb-8 {
  padding-bottom: 8rem !important; }

.pl-8 {
  padding-left: 8rem !important; }

.px-8 {
  padding-right: 8rem !important;
  padding-left: 8rem !important; }

.py-8 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important; }

.p-9 {
  padding: 9rem !important; }

.pt-9 {
  padding-top: 9rem !important; }

.pr-9 {
  padding-right: 9rem !important; }

.pb-9 {
  padding-bottom: 9rem !important; }

.pl-9 {
  padding-left: 9rem !important; }

.px-9 {
  padding-right: 9rem !important;
  padding-left: 9rem !important; }

.py-9 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important; }

@media (min-width: 576px) {
  .m-sm-6 {
    margin: 6rem !important; }
  .mt-sm-6 {
    margin-top: 6rem !important; }
  .mr-sm-6 {
    margin-right: 6rem !important; }
  .mb-sm-6 {
    margin-bottom: 6rem !important; }
  .ml-sm-6 {
    margin-left: 6rem !important; }
  .mx-sm-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .my-sm-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .m-sm-7 {
    margin: 7rem !important; }
  .mt-sm-7 {
    margin-top: 7rem !important; }
  .mr-sm-7 {
    margin-right: 7rem !important; }
  .mb-sm-7 {
    margin-bottom: 7rem !important; }
  .ml-sm-7 {
    margin-left: 7rem !important; }
  .mx-sm-7 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .my-sm-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .m-sm-8 {
    margin: 8rem !important; }
  .mt-sm-8 {
    margin-top: 8rem !important; }
  .mr-sm-8 {
    margin-right: 8rem !important; }
  .mb-sm-8 {
    margin-bottom: 8rem !important; }
  .ml-sm-8 {
    margin-left: 8rem !important; }
  .mx-sm-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important; }
  .my-sm-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .m-sm-9 {
    margin: 9rem !important; }
  .mt-sm-9 {
    margin-top: 9rem !important; }
  .mr-sm-9 {
    margin-right: 9rem !important; }
  .mb-sm-9 {
    margin-bottom: 9rem !important; }
  .ml-sm-9 {
    margin-left: 9rem !important; }
  .mx-sm-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .my-sm-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .p-sm-6 {
    padding: 6rem !important; }
  .pt-sm-6 {
    padding-top: 6rem !important; }
  .pr-sm-6 {
    padding-right: 6rem !important; }
  .pb-sm-6 {
    padding-bottom: 6rem !important; }
  .pl-sm-6 {
    padding-left: 6rem !important; }
  .px-sm-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .py-sm-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .p-sm-7 {
    padding: 7rem !important; }
  .pt-sm-7 {
    padding-top: 7rem !important; }
  .pr-sm-7 {
    padding-right: 7rem !important; }
  .pb-sm-7 {
    padding-bottom: 7rem !important; }
  .pl-sm-7 {
    padding-left: 7rem !important; }
  .px-sm-7 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .py-sm-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .p-sm-8 {
    padding: 8rem !important; }
  .pt-sm-8 {
    padding-top: 8rem !important; }
  .pr-sm-8 {
    padding-right: 8rem !important; }
  .pb-sm-8 {
    padding-bottom: 8rem !important; }
  .pl-sm-8 {
    padding-left: 8rem !important; }
  .px-sm-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important; }
  .py-sm-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .p-sm-9 {
    padding: 9rem !important; }
  .pt-sm-9 {
    padding-top: 9rem !important; }
  .pr-sm-9 {
    padding-right: 9rem !important; }
  .pb-sm-9 {
    padding-bottom: 9rem !important; }
  .pl-sm-9 {
    padding-left: 9rem !important; }
  .px-sm-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .py-sm-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; } }

@media (min-width: 768px) {
  .m-md-6 {
    margin: 6rem !important; }
  .mt-md-6 {
    margin-top: 6rem !important; }
  .mr-md-6 {
    margin-right: 6rem !important; }
  .mb-md-6 {
    margin-bottom: 6rem !important; }
  .ml-md-6 {
    margin-left: 6rem !important; }
  .mx-md-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .my-md-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .m-md-7 {
    margin: 7rem !important; }
  .mt-md-7 {
    margin-top: 7rem !important; }
  .mr-md-7 {
    margin-right: 7rem !important; }
  .mb-md-7 {
    margin-bottom: 7rem !important; }
  .ml-md-7 {
    margin-left: 7rem !important; }
  .mx-md-7 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .my-md-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .m-md-8 {
    margin: 8rem !important; }
  .mt-md-8 {
    margin-top: 8rem !important; }
  .mr-md-8 {
    margin-right: 8rem !important; }
  .mb-md-8 {
    margin-bottom: 8rem !important; }
  .ml-md-8 {
    margin-left: 8rem !important; }
  .mx-md-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important; }
  .my-md-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .m-md-9 {
    margin: 9rem !important; }
  .mt-md-9 {
    margin-top: 9rem !important; }
  .mr-md-9 {
    margin-right: 9rem !important; }
  .mb-md-9 {
    margin-bottom: 9rem !important; }
  .ml-md-9 {
    margin-left: 9rem !important; }
  .mx-md-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .my-md-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .p-md-6 {
    padding: 6rem !important; }
  .pt-md-6 {
    padding-top: 6rem !important; }
  .pr-md-6 {
    padding-right: 6rem !important; }
  .pb-md-6 {
    padding-bottom: 6rem !important; }
  .pl-md-6 {
    padding-left: 6rem !important; }
  .px-md-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .py-md-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .p-md-7 {
    padding: 7rem !important; }
  .pt-md-7 {
    padding-top: 7rem !important; }
  .pr-md-7 {
    padding-right: 7rem !important; }
  .pb-md-7 {
    padding-bottom: 7rem !important; }
  .pl-md-7 {
    padding-left: 7rem !important; }
  .px-md-7 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .py-md-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .p-md-8 {
    padding: 8rem !important; }
  .pt-md-8 {
    padding-top: 8rem !important; }
  .pr-md-8 {
    padding-right: 8rem !important; }
  .pb-md-8 {
    padding-bottom: 8rem !important; }
  .pl-md-8 {
    padding-left: 8rem !important; }
  .px-md-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important; }
  .py-md-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .p-md-9 {
    padding: 9rem !important; }
  .pt-md-9 {
    padding-top: 9rem !important; }
  .pr-md-9 {
    padding-right: 9rem !important; }
  .pb-md-9 {
    padding-bottom: 9rem !important; }
  .pl-md-9 {
    padding-left: 9rem !important; }
  .px-md-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .py-md-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; } }

@media (min-width: 992px) {
  .m-lg-6 {
    margin: 6rem !important; }
  .mt-lg-6 {
    margin-top: 6rem !important; }
  .mr-lg-6 {
    margin-right: 6rem !important; }
  .mb-lg-6 {
    margin-bottom: 6rem !important; }
  .ml-lg-6 {
    margin-left: 6rem !important; }
  .mx-lg-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .my-lg-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .m-lg-7 {
    margin: 7rem !important; }
  .mt-lg-7 {
    margin-top: 7rem !important; }
  .mr-lg-7 {
    margin-right: 7rem !important; }
  .mb-lg-7 {
    margin-bottom: 7rem !important; }
  .ml-lg-7 {
    margin-left: 7rem !important; }
  .mx-lg-7 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .my-lg-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .m-lg-8 {
    margin: 8rem !important; }
  .mt-lg-8 {
    margin-top: 8rem !important; }
  .mr-lg-8 {
    margin-right: 8rem !important; }
  .mb-lg-8 {
    margin-bottom: 8rem !important; }
  .ml-lg-8 {
    margin-left: 8rem !important; }
  .mx-lg-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important; }
  .my-lg-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .m-lg-9 {
    margin: 9rem !important; }
  .mt-lg-9 {
    margin-top: 9rem !important; }
  .mr-lg-9 {
    margin-right: 9rem !important; }
  .mb-lg-9 {
    margin-bottom: 9rem !important; }
  .ml-lg-9 {
    margin-left: 9rem !important; }
  .mx-lg-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .my-lg-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .p-lg-6 {
    padding: 6rem !important; }
  .pt-lg-6 {
    padding-top: 6rem !important; }
  .pr-lg-6 {
    padding-right: 6rem !important; }
  .pb-lg-6 {
    padding-bottom: 6rem !important; }
  .pl-lg-6 {
    padding-left: 6rem !important; }
  .px-lg-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .py-lg-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .p-lg-7 {
    padding: 7rem !important; }
  .pt-lg-7 {
    padding-top: 7rem !important; }
  .pr-lg-7 {
    padding-right: 7rem !important; }
  .pb-lg-7 {
    padding-bottom: 7rem !important; }
  .pl-lg-7 {
    padding-left: 7rem !important; }
  .px-lg-7 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .py-lg-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .p-lg-8 {
    padding: 8rem !important; }
  .pt-lg-8 {
    padding-top: 8rem !important; }
  .pr-lg-8 {
    padding-right: 8rem !important; }
  .pb-lg-8 {
    padding-bottom: 8rem !important; }
  .pl-lg-8 {
    padding-left: 8rem !important; }
  .px-lg-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important; }
  .py-lg-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .p-lg-9 {
    padding: 9rem !important; }
  .pt-lg-9 {
    padding-top: 9rem !important; }
  .pr-lg-9 {
    padding-right: 9rem !important; }
  .pb-lg-9 {
    padding-bottom: 9rem !important; }
  .pl-lg-9 {
    padding-left: 9rem !important; }
  .px-lg-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .py-lg-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; } }

@media (min-width: 1200px) {
  .m-xl-6 {
    margin: 6rem !important; }
  .mt-xl-6 {
    margin-top: 6rem !important; }
  .mr-xl-6 {
    margin-right: 6rem !important; }
  .mb-xl-6 {
    margin-bottom: 6rem !important; }
  .ml-xl-6 {
    margin-left: 6rem !important; }
  .mx-xl-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .my-xl-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .m-xl-7 {
    margin: 7rem !important; }
  .mt-xl-7 {
    margin-top: 7rem !important; }
  .mr-xl-7 {
    margin-right: 7rem !important; }
  .mb-xl-7 {
    margin-bottom: 7rem !important; }
  .ml-xl-7 {
    margin-left: 7rem !important; }
  .mx-xl-7 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .my-xl-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .m-xl-8 {
    margin: 8rem !important; }
  .mt-xl-8 {
    margin-top: 8rem !important; }
  .mr-xl-8 {
    margin-right: 8rem !important; }
  .mb-xl-8 {
    margin-bottom: 8rem !important; }
  .ml-xl-8 {
    margin-left: 8rem !important; }
  .mx-xl-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important; }
  .my-xl-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .m-xl-9 {
    margin: 9rem !important; }
  .mt-xl-9 {
    margin-top: 9rem !important; }
  .mr-xl-9 {
    margin-right: 9rem !important; }
  .mb-xl-9 {
    margin-bottom: 9rem !important; }
  .ml-xl-9 {
    margin-left: 9rem !important; }
  .mx-xl-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .my-xl-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .p-xl-6 {
    padding: 6rem !important; }
  .pt-xl-6 {
    padding-top: 6rem !important; }
  .pr-xl-6 {
    padding-right: 6rem !important; }
  .pb-xl-6 {
    padding-bottom: 6rem !important; }
  .pl-xl-6 {
    padding-left: 6rem !important; }
  .px-xl-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .py-xl-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .p-xl-7 {
    padding: 7rem !important; }
  .pt-xl-7 {
    padding-top: 7rem !important; }
  .pr-xl-7 {
    padding-right: 7rem !important; }
  .pb-xl-7 {
    padding-bottom: 7rem !important; }
  .pl-xl-7 {
    padding-left: 7rem !important; }
  .px-xl-7 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .py-xl-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .p-xl-8 {
    padding: 8rem !important; }
  .pt-xl-8 {
    padding-top: 8rem !important; }
  .pr-xl-8 {
    padding-right: 8rem !important; }
  .pb-xl-8 {
    padding-bottom: 8rem !important; }
  .pl-xl-8 {
    padding-left: 8rem !important; }
  .px-xl-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important; }
  .py-xl-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .p-xl-9 {
    padding: 9rem !important; }
  .pt-xl-9 {
    padding-top: 9rem !important; }
  .pr-xl-9 {
    padding-right: 9rem !important; }
  .pb-xl-9 {
    padding-bottom: 9rem !important; }
  .pl-xl-9 {
    padding-left: 9rem !important; }
  .px-xl-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .py-xl-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; } }

.mt-0n {
  margin-top: 0 !important; }

.mb-0n {
  margin-bottom: 0 !important; }

.mt-1n {
  margin-top: -0.25rem !important; }

.mb-1n {
  margin-bottom: -0.25rem !important; }

.mt-2n {
  margin-top: -0.5rem !important; }

.mb-2n {
  margin-bottom: -0.5rem !important; }

.mt-3n {
  margin-top: -1rem !important; }

.mb-3n {
  margin-bottom: -1rem !important; }

.mt-4n {
  margin-top: -1.5rem !important; }

.mb-4n {
  margin-bottom: -1.5rem !important; }

.mt-5n {
  margin-top: -3rem !important; }

.mb-5n {
  margin-bottom: -3rem !important; }

.mt-6n {
  margin-top: -6rem !important; }

.mb-6n {
  margin-bottom: -6rem !important; }

.mt-7n {
  margin-top: -7rem !important; }

.mb-7n {
  margin-bottom: -7rem !important; }

.mt-8n {
  margin-top: -8rem !important; }

.mb-8n {
  margin-bottom: -8rem !important; }

.mt-9n {
  margin-top: -9rem !important; }

.mb-9n {
  margin-bottom: -9rem !important; }

.pt-0n {
  padding-top: 0 !important; }

.pb-0n {
  padding-bottom: 0 !important; }

.pt-1n {
  padding-top: -0.25rem !important; }

.pb-1n {
  padding-bottom: -0.25rem !important; }

.pt-2n {
  padding-top: -0.5rem !important; }

.pb-2n {
  padding-bottom: -0.5rem !important; }

.pt-3n {
  padding-top: -1rem !important; }

.pb-3n {
  padding-bottom: -1rem !important; }

.pt-4n {
  padding-top: -1.5rem !important; }

.pb-4n {
  padding-bottom: -1.5rem !important; }

.pt-5n {
  padding-top: -3rem !important; }

.pb-5n {
  padding-bottom: -3rem !important; }

.pt-6n {
  padding-top: -6rem !important; }

.pb-6n {
  padding-bottom: -6rem !important; }

.pt-7n {
  padding-top: -7rem !important; }

.pb-7n {
  padding-bottom: -7rem !important; }

.pt-8n {
  padding-top: -8rem !important; }

.pb-8n {
  padding-bottom: -8rem !important; }

.pt-9n {
  padding-top: -9rem !important; }

.pb-9n {
  padding-bottom: -9rem !important; }

@media (min-width: 576px) {
  .mt-sm-0n {
    margin-top: 0 !important; }
  .mb-sm-0n {
    margin-bottom: 0 !important; }
  .mt-sm-1n {
    margin-top: -0.25rem !important; }
  .mb-sm-1n {
    margin-bottom: -0.25rem !important; }
  .mt-sm-2n {
    margin-top: -0.5rem !important; }
  .mb-sm-2n {
    margin-bottom: -0.5rem !important; }
  .mt-sm-3n {
    margin-top: -1rem !important; }
  .mb-sm-3n {
    margin-bottom: -1rem !important; }
  .mt-sm-4n {
    margin-top: -1.5rem !important; }
  .mb-sm-4n {
    margin-bottom: -1.5rem !important; }
  .mt-sm-5n {
    margin-top: -3rem !important; }
  .mb-sm-5n {
    margin-bottom: -3rem !important; }
  .mt-sm-6n {
    margin-top: -6rem !important; }
  .mb-sm-6n {
    margin-bottom: -6rem !important; }
  .mt-sm-7n {
    margin-top: -7rem !important; }
  .mb-sm-7n {
    margin-bottom: -7rem !important; }
  .mt-sm-8n {
    margin-top: -8rem !important; }
  .mb-sm-8n {
    margin-bottom: -8rem !important; }
  .mt-sm-9n {
    margin-top: -9rem !important; }
  .mb-sm-9n {
    margin-bottom: -9rem !important; }
  .pt-sm-0n {
    padding-top: 0 !important; }
  .pb-sm-0n {
    padding-bottom: 0 !important; }
  .pt-sm-1n {
    padding-top: -0.25rem !important; }
  .pb-sm-1n {
    padding-bottom: -0.25rem !important; }
  .pt-sm-2n {
    padding-top: -0.5rem !important; }
  .pb-sm-2n {
    padding-bottom: -0.5rem !important; }
  .pt-sm-3n {
    padding-top: -1rem !important; }
  .pb-sm-3n {
    padding-bottom: -1rem !important; }
  .pt-sm-4n {
    padding-top: -1.5rem !important; }
  .pb-sm-4n {
    padding-bottom: -1.5rem !important; }
  .pt-sm-5n {
    padding-top: -3rem !important; }
  .pb-sm-5n {
    padding-bottom: -3rem !important; }
  .pt-sm-6n {
    padding-top: -6rem !important; }
  .pb-sm-6n {
    padding-bottom: -6rem !important; }
  .pt-sm-7n {
    padding-top: -7rem !important; }
  .pb-sm-7n {
    padding-bottom: -7rem !important; }
  .pt-sm-8n {
    padding-top: -8rem !important; }
  .pb-sm-8n {
    padding-bottom: -8rem !important; }
  .pt-sm-9n {
    padding-top: -9rem !important; }
  .pb-sm-9n {
    padding-bottom: -9rem !important; } }

@media (min-width: 768px) {
  .mt-md-0n {
    margin-top: 0 !important; }
  .mb-md-0n {
    margin-bottom: 0 !important; }
  .mt-md-1n {
    margin-top: -0.25rem !important; }
  .mb-md-1n {
    margin-bottom: -0.25rem !important; }
  .mt-md-2n {
    margin-top: -0.5rem !important; }
  .mb-md-2n {
    margin-bottom: -0.5rem !important; }
  .mt-md-3n {
    margin-top: -1rem !important; }
  .mb-md-3n {
    margin-bottom: -1rem !important; }
  .mt-md-4n {
    margin-top: -1.5rem !important; }
  .mb-md-4n {
    margin-bottom: -1.5rem !important; }
  .mt-md-5n {
    margin-top: -3rem !important; }
  .mb-md-5n {
    margin-bottom: -3rem !important; }
  .mt-md-6n {
    margin-top: -6rem !important; }
  .mb-md-6n {
    margin-bottom: -6rem !important; }
  .mt-md-7n {
    margin-top: -7rem !important; }
  .mb-md-7n {
    margin-bottom: -7rem !important; }
  .mt-md-8n {
    margin-top: -8rem !important; }
  .mb-md-8n {
    margin-bottom: -8rem !important; }
  .mt-md-9n {
    margin-top: -9rem !important; }
  .mb-md-9n {
    margin-bottom: -9rem !important; }
  .pt-md-0n {
    padding-top: 0 !important; }
  .pb-md-0n {
    padding-bottom: 0 !important; }
  .pt-md-1n {
    padding-top: -0.25rem !important; }
  .pb-md-1n {
    padding-bottom: -0.25rem !important; }
  .pt-md-2n {
    padding-top: -0.5rem !important; }
  .pb-md-2n {
    padding-bottom: -0.5rem !important; }
  .pt-md-3n {
    padding-top: -1rem !important; }
  .pb-md-3n {
    padding-bottom: -1rem !important; }
  .pt-md-4n {
    padding-top: -1.5rem !important; }
  .pb-md-4n {
    padding-bottom: -1.5rem !important; }
  .pt-md-5n {
    padding-top: -3rem !important; }
  .pb-md-5n {
    padding-bottom: -3rem !important; }
  .pt-md-6n {
    padding-top: -6rem !important; }
  .pb-md-6n {
    padding-bottom: -6rem !important; }
  .pt-md-7n {
    padding-top: -7rem !important; }
  .pb-md-7n {
    padding-bottom: -7rem !important; }
  .pt-md-8n {
    padding-top: -8rem !important; }
  .pb-md-8n {
    padding-bottom: -8rem !important; }
  .pt-md-9n {
    padding-top: -9rem !important; }
  .pb-md-9n {
    padding-bottom: -9rem !important; } }

@media (min-width: 992px) {
  .mt-lg-0n {
    margin-top: 0 !important; }
  .mb-lg-0n {
    margin-bottom: 0 !important; }
  .mt-lg-1n {
    margin-top: -0.25rem !important; }
  .mb-lg-1n {
    margin-bottom: -0.25rem !important; }
  .mt-lg-2n {
    margin-top: -0.5rem !important; }
  .mb-lg-2n {
    margin-bottom: -0.5rem !important; }
  .mt-lg-3n {
    margin-top: -1rem !important; }
  .mb-lg-3n {
    margin-bottom: -1rem !important; }
  .mt-lg-4n {
    margin-top: -1.5rem !important; }
  .mb-lg-4n {
    margin-bottom: -1.5rem !important; }
  .mt-lg-5n {
    margin-top: -3rem !important; }
  .mb-lg-5n {
    margin-bottom: -3rem !important; }
  .mt-lg-6n {
    margin-top: -6rem !important; }
  .mb-lg-6n {
    margin-bottom: -6rem !important; }
  .mt-lg-7n {
    margin-top: -7rem !important; }
  .mb-lg-7n {
    margin-bottom: -7rem !important; }
  .mt-lg-8n {
    margin-top: -8rem !important; }
  .mb-lg-8n {
    margin-bottom: -8rem !important; }
  .mt-lg-9n {
    margin-top: -9rem !important; }
  .mb-lg-9n {
    margin-bottom: -9rem !important; }
  .pt-lg-0n {
    padding-top: 0 !important; }
  .pb-lg-0n {
    padding-bottom: 0 !important; }
  .pt-lg-1n {
    padding-top: -0.25rem !important; }
  .pb-lg-1n {
    padding-bottom: -0.25rem !important; }
  .pt-lg-2n {
    padding-top: -0.5rem !important; }
  .pb-lg-2n {
    padding-bottom: -0.5rem !important; }
  .pt-lg-3n {
    padding-top: -1rem !important; }
  .pb-lg-3n {
    padding-bottom: -1rem !important; }
  .pt-lg-4n {
    padding-top: -1.5rem !important; }
  .pb-lg-4n {
    padding-bottom: -1.5rem !important; }
  .pt-lg-5n {
    padding-top: -3rem !important; }
  .pb-lg-5n {
    padding-bottom: -3rem !important; }
  .pt-lg-6n {
    padding-top: -6rem !important; }
  .pb-lg-6n {
    padding-bottom: -6rem !important; }
  .pt-lg-7n {
    padding-top: -7rem !important; }
  .pb-lg-7n {
    padding-bottom: -7rem !important; }
  .pt-lg-8n {
    padding-top: -8rem !important; }
  .pb-lg-8n {
    padding-bottom: -8rem !important; }
  .pt-lg-9n {
    padding-top: -9rem !important; }
  .pb-lg-9n {
    padding-bottom: -9rem !important; } }

@media (min-width: 1200px) {
  .mt-xl-0n {
    margin-top: 0 !important; }
  .mb-xl-0n {
    margin-bottom: 0 !important; }
  .mt-xl-1n {
    margin-top: -0.25rem !important; }
  .mb-xl-1n {
    margin-bottom: -0.25rem !important; }
  .mt-xl-2n {
    margin-top: -0.5rem !important; }
  .mb-xl-2n {
    margin-bottom: -0.5rem !important; }
  .mt-xl-3n {
    margin-top: -1rem !important; }
  .mb-xl-3n {
    margin-bottom: -1rem !important; }
  .mt-xl-4n {
    margin-top: -1.5rem !important; }
  .mb-xl-4n {
    margin-bottom: -1.5rem !important; }
  .mt-xl-5n {
    margin-top: -3rem !important; }
  .mb-xl-5n {
    margin-bottom: -3rem !important; }
  .mt-xl-6n {
    margin-top: -6rem !important; }
  .mb-xl-6n {
    margin-bottom: -6rem !important; }
  .mt-xl-7n {
    margin-top: -7rem !important; }
  .mb-xl-7n {
    margin-bottom: -7rem !important; }
  .mt-xl-8n {
    margin-top: -8rem !important; }
  .mb-xl-8n {
    margin-bottom: -8rem !important; }
  .mt-xl-9n {
    margin-top: -9rem !important; }
  .mb-xl-9n {
    margin-bottom: -9rem !important; }
  .pt-xl-0n {
    padding-top: 0 !important; }
  .pb-xl-0n {
    padding-bottom: 0 !important; }
  .pt-xl-1n {
    padding-top: -0.25rem !important; }
  .pb-xl-1n {
    padding-bottom: -0.25rem !important; }
  .pt-xl-2n {
    padding-top: -0.5rem !important; }
  .pb-xl-2n {
    padding-bottom: -0.5rem !important; }
  .pt-xl-3n {
    padding-top: -1rem !important; }
  .pb-xl-3n {
    padding-bottom: -1rem !important; }
  .pt-xl-4n {
    padding-top: -1.5rem !important; }
  .pb-xl-4n {
    padding-bottom: -1.5rem !important; }
  .pt-xl-5n {
    padding-top: -3rem !important; }
  .pb-xl-5n {
    padding-bottom: -3rem !important; }
  .pt-xl-6n {
    padding-top: -6rem !important; }
  .pb-xl-6n {
    padding-bottom: -6rem !important; }
  .pt-xl-7n {
    padding-top: -7rem !important; }
  .pb-xl-7n {
    padding-bottom: -7rem !important; }
  .pt-xl-8n {
    padding-top: -8rem !important; }
  .pb-xl-8n {
    padding-bottom: -8rem !important; }
  .pt-xl-9n {
    padding-top: -9rem !important; }
  .pb-xl-9n {
    padding-bottom: -9rem !important; } }

.speech-bubble:after, .speech-bubble-outlined:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 15px solid transparent; }

.speech-bubble {
  position: relative;
  background: #fff;
  border-radius: 0.5rem;
  padding: 1.5rem; }
  .speech-bubble.arrow-bottom:after {
    border-bottom: 0;
    border-top-color: #fff;
    margin-bottom: -15px;
    bottom: 0; }
  .speech-bubble.arrow-bottom--center:after {
    left: 50%;
    margin-left: -7.5px; }
  .speech-bubble.arrow-bottom--left:after {
    border-left: 0;
    left: 25%; }
  .speech-bubble.arrow-bottom--right:after {
    border-right: 0;
    left: 75%; }
  .speech-bubble.bg-primary:after {
    border-top-color: #ff6f61; }
  .speech-bubble.bg-secondary:after {
    border-top-color: #fc9e21; }
  .speech-bubble.bg-success:after {
    border-top-color: #37bd74; }
  .speech-bubble.bg-info:after {
    border-top-color: #11cdef; }
  .speech-bubble.bg-warning:after {
    border-top-color: #dc793e; }
  .speech-bubble.bg-danger:after {
    border-top-color: #f44236; }
  .speech-bubble.bg-light:after {
    border-top-color: #edf4f8; }
  .speech-bubble.bg-dark:after {
    border-top-color: #181715; }
  .speech-bubble.bg-alternate:after {
    border-top-color: #007cb7; }
  .speech-bubble.bg-contrast:after {
    border-top-color: #fff; }
  .speech-bubble-outlined {
    border: 1px solid #fff;
    background: #fff; }
    .speech-bubble-outlined:before {
      border-width: 13px; }
    .speech-bubble-outlined.arrow-bottom:before {
      bottom: 0;
      border-bottom: 0;
      border-top-color: #fff;
      margin-bottom: -13px;
      z-index: 1; }
    .speech-bubble-outlined.arrow-bottom--center:before {
      left: 50%;
      margin-left: -5.5px; }
    .speech-bubble-outlined.arrow-bottom--left:before {
      left: 25%;
      border-left: 0;
      margin-left: 1px; }
    .speech-bubble-outlined.arrow-bottom--right:before {
      left: 75%;
      border-right: 0;
      margin-left: 1px; }
    .speech-bubble-outlined.arrow-bottom.border-primary:before {
      border-top-color: #ff6f61; }
    .speech-bubble-outlined.arrow-bottom.border-primary:after {
      border-top-color: #ff6f61; }
    .speech-bubble-outlined.arrow-bottom.border-secondary:before {
      border-top-color: #fc9e21; }
    .speech-bubble-outlined.arrow-bottom.border-secondary:after {
      border-top-color: #fc9e21; }
    .speech-bubble-outlined.arrow-bottom.border-success:before {
      border-top-color: #37bd74; }
    .speech-bubble-outlined.arrow-bottom.border-success:after {
      border-top-color: #37bd74; }
    .speech-bubble-outlined.arrow-bottom.border-info:before {
      border-top-color: #11cdef; }
    .speech-bubble-outlined.arrow-bottom.border-info:after {
      border-top-color: #11cdef; }
    .speech-bubble-outlined.arrow-bottom.border-warning:before {
      border-top-color: #dc793e; }
    .speech-bubble-outlined.arrow-bottom.border-warning:after {
      border-top-color: #dc793e; }
    .speech-bubble-outlined.arrow-bottom.border-danger:before {
      border-top-color: #f44236; }
    .speech-bubble-outlined.arrow-bottom.border-danger:after {
      border-top-color: #f44236; }
    .speech-bubble-outlined.arrow-bottom.border-light:before {
      border-top-color: #edf4f8; }
    .speech-bubble-outlined.arrow-bottom.border-light:after {
      border-top-color: #edf4f8; }
    .speech-bubble-outlined.arrow-bottom.border-dark:before {
      border-top-color: #181715; }
    .speech-bubble-outlined.arrow-bottom.border-dark:after {
      border-top-color: #181715; }
    .speech-bubble-outlined.arrow-bottom.border-alternate:before {
      border-top-color: #007cb7; }
    .speech-bubble-outlined.arrow-bottom.border-alternate:after {
      border-top-color: #007cb7; }
    .speech-bubble-outlined.arrow-bottom.border-contrast:before {
      border-top-color: #fff; }
    .speech-bubble-outlined.arrow-bottom.border-contrast:after {
      border-top-color: #fff; }
  .speech-bubble > .rounded {
    border-radius: 0.5rem !important; }

/* 2.10 Text ======================================================= */
.italic {
  font-style: italic !important; }

.underline {
  text-decoration: underline !important; }

.dotted {
  border-bottom: 1px dotted; }

.strike-through, s {
  text-decoration: line-through !important;
  color: #b5bab6;
  font-weight: 300; }

.text-wrap {
  white-space: normal !important; }

.thin {
  font-weight: 100 !important; }

.light {
  font-weight: 300 !important; }

.regular {
  font-weight: 400 !important; }

.semi-bold {
  font-weight: 600 !important; }

.bold, strong {
  font-weight: 700 !important; }

.extra-bold {
  font-weight: 900 !important; }

.accent {
  color: #ff6f61; }

/* 2.11 Type ======================================================= */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  letter-spacing: 0.05rem; }

.font-regular {
  font-size: 1rem !important; }

.font-xs {
  font-size: 0.45rem !important; }

.font-sm {
  font-size: 0.85rem !important; }

.font-md {
  font-size: 2rem !important; }

.font-lg {
  font-size: 1.25rem !important; }

.font-xl {
  font-size: 4.5rem !important; }

.font-xxl {
  font-size: 6.5rem !important; }

.display-1 {
  font-size: 6rem !important; }

.display-2 {
  font-size: 5.5rem !important; }

.display-3 {
  font-size: 4.5rem !important; }

.display-4 {
  font-size: 3.5rem !important; }

@media (min-width: 576px) {
  .display-sm-1 {
    font-size: 6rem !important; }
  .display-sm-2 {
    font-size: 5.5rem !important; }
  .display-sm-3 {
    font-size: 4.5rem !important; }
  .display-sm-4 {
    font-size: 3.5rem !important; } }

@media (min-width: 768px) {
  .display-md-1 {
    font-size: 6rem !important; }
  .display-md-2 {
    font-size: 5.5rem !important; }
  .display-md-3 {
    font-size: 4.5rem !important; }
  .display-md-4 {
    font-size: 3.5rem !important; } }

@media (min-width: 992px) {
  .display-lg-1 {
    font-size: 6rem !important; }
  .display-lg-2 {
    font-size: 5.5rem !important; }
  .display-lg-3 {
    font-size: 4.5rem !important; }
  .display-lg-4 {
    font-size: 3.5rem !important; } }

@media (min-width: 1200px) {
  .display-xl-1 {
    font-size: 6rem !important; }
  .display-xl-2 {
    font-size: 5.5rem !important; }
  .display-xl-3 {
    font-size: 4.5rem !important; }
  .display-xl-4 {
    font-size: 3.5rem !important; } }

/* ==========================================================================
    [7. COMPONENTS]
========================================================================== */
[data-toggle="collapse"] {
  display: flex;
  align-items: center; }
  [data-toggle="collapse"]:after {
    font-family: "Font Awesome 5 Free";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-weight: 900;
    content: "\f107";
    transition: transform .3s ease;
    margin-left: auto; }
  [data-toggle="collapse"].collapsed:after {
    transform: rotate(-90deg); }

.navbar-nav {
  position: relative; }

@media (min-width: 768px) {
  .navbar .dropdown.mega {
    position: static; } }

/* 3.1 Navigation base styles ======================================================= */
main {
  transition: transform 0.4s ease 0s;
  overflow: hidden; }

.navigation {
  background-color: #fff;
  min-height: 90px;
  transition: all 0.3s ease 0s; }
  @media (max-width: 767.98px) {
    .navigation .container {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between; }
    .navigation .dropdown-item:before {
      content: '';
      padding: 3px;
      left: 0;
      border-radius: 50%;
      opacity: 0.5; }
    .navigation .dropdown-item:before {
      position: absolute;
      top: 12px; }
    .navigation .dropdown-heading {
      padding-left: 0 !important; } }
  .navigation .navbar-toggler {
    align-self: center; }
    .navigation .navbar-toggler .icon-bar {
      transform: rotate(0deg);
      background-color: #ff6f61;
      transition: all .35s ease-in-out;
      display: block;
      width: 30px;
      height: 2px; }
      .navigation .navbar-toggler .icon-bar + .icon-bar {
        margin-top: 6px; }
      .navigation .navbar-toggler .icon-bar:hover {
        background-color: #cb584d; }
  .navigation .dropdown-heading {
    margin: 0;
    padding: 0.5rem 1.5rem;
    font-weight: 700;
    position: relative; }
  .navigation.fixed-top .navbar-collapse {
    height: 100vh; }
  .navigation.navbar-expanded.sidebar-left .collapse {
    left: 0; }
  .navigation.navbar-expanded.sidebar-left .navbar-toggler,
  .navigation.navbar-expanded.sidebar-left .navbar-brand,
  .navigation.navbar-expanded.sidebar-left ~ main {
    transform: translateX(250px); }
  .navigation.navbar-expanded.sidebar-right .collapse {
    right: 0; }
  .navigation.navbar-expanded.sidebar-right .navbar-toggler,
  .navigation.navbar-expanded.sidebar-right .navbar-brand,
  .navigation.navbar-expanded.sidebar-right ~ main {
    transform: translateX(-250px); }
  .navigation.navbar-expanded .icon-bar:nth-child(1) {
    transform: rotate(-225deg); }
  .navigation.navbar-expanded .icon-bar:nth-child(2) {
    transform: translateX(-40px);
    opacity: 0; }
  .navigation.navbar-expanded .icon-bar:nth-child(3) {
    transform: translateY(-15px) rotate(225deg); }
  .navigation.sidebar-left .collapse {
    left: -250px;
    border-right: 1px solid #edf4f8; }
  .navigation.sidebar-right .collapse {
    right: -250px;
    border-left: 1px solid #edf4f8; }
  .navigation.sidebar-right .navbar-toggler {
    order: 2; }
  .navigation.sidebar-right .navbar-brand {
    order: 1; }
  .navigation .collapse {
    transition: left 0.4s ease 0s;
    background-color: #fff;
    width: 250px;
    position: fixed;
    margin: 0;
    top: 0;
    bottom: 0;
    display: block; }
    @media (max-width: 767.98px) {
      .navigation .collapse {
        overflow-y: auto; } }
  .navigation .logo {
    height: 5rem;
    max-height: 5rem; }
  .navigation .dropdown-menu {
    font-size: 0.875rem;
    padding: 1rem 0;
    border: none; }
    .navigation .dropdown-menu .dropdown-item {
      color: #2f3e4b;
      position: relative; }
      .navigation .dropdown-menu .dropdown-item.disabled {
        opacity: 0.4; }
  .navigation .sidebar-brand {
    padding: 1rem; }
  .navigation .nav-link {
    color: #ff6f61;
    font-family: "Lato", sans-serif;
    font-size: 0.9rem;
    font-weight: 700;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
    text-decoration: none;
    letter-spacing: 1px;
    padding: 1rem; }
    .navigation .nav-link i {
      display: inline-block;
      margin-right: 15px; }
    .navigation .nav-link.active, .navigation .nav-link:hover {
      color: #fff; }
      @media (max-width: 767.98px) {
        .navigation .nav-link.active, .navigation .nav-link:hover {
          background: #ff6f61; } }
  .navigation.navbar-sticky {
    position: fixed !important;
    background-color: #fff;
    left: 0;
    top: 0;
    right: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.11);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.11);
    min-height: 90px;
    z-index: 1030;
    transition: all .6s ease-in-out; }
  .navigation .btn-outline, .navigation.navbar-sticky .btn-outline {
    color: #ff6f61;
    border-color: #ff6f61; }
    .navigation .btn-outline:hover, .navigation.navbar-sticky .btn-outline:hover {
      color: #212529;
      background-color: #ff6f61;
      border-color: #ff6f61; }
    .navigation .btn-outline:focus, .navigation .btn-outline.focus, .navigation.navbar-sticky .btn-outline:focus, .navigation.navbar-sticky .btn-outline.focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 111, 97, 0.5); }
    .navigation .btn-outline.disabled, .navigation .btn-outline:disabled, .navigation.navbar-sticky .btn-outline.disabled, .navigation.navbar-sticky .btn-outline:disabled {
      color: #ff6f61;
      background-color: transparent; }
    .navigation .btn-outline:not(:disabled):not(.disabled):active, .navigation .btn-outline:not(:disabled):not(.disabled).active,
    .show > .navigation .btn-outline.dropdown-toggle, .navigation.navbar-sticky .btn-outline:not(:disabled):not(.disabled):active, .navigation.navbar-sticky .btn-outline:not(:disabled):not(.disabled).active,
    .show > .navigation.navbar-sticky .btn-outline.dropdown-toggle {
      color: #212529;
      background-color: #ff6f61;
      border-color: #ff6f61; }
      .navigation .btn-outline:not(:disabled):not(.disabled):active:focus, .navigation .btn-outline:not(:disabled):not(.disabled).active:focus,
      .show > .navigation .btn-outline.dropdown-toggle:focus, .navigation.navbar-sticky .btn-outline:not(:disabled):not(.disabled):active:focus, .navigation.navbar-sticky .btn-outline:not(:disabled):not(.disabled).active:focus,
      .show > .navigation.navbar-sticky .btn-outline.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(255, 111, 97, 0.5); }
    .navigation .btn-outline:hover, .navigation.navbar-sticky .btn-outline:hover {
      box-shadow: 0 5px 10px rgba(255, 111, 97, 0.4) !important; }
  .navigation .btn-solid, .navigation.navbar-sticky .btn-solid {
    color: #212529;
    background-color: #ff6f61;
    border-color: #ff6f61; }
    .navigation .btn-solid:hover, .navigation.navbar-sticky .btn-solid:hover {
      color: #fff;
      background-color: #ff4c3b;
      border-color: #ff412e; }
    .navigation .btn-solid:focus, .navigation .btn-solid.focus, .navigation.navbar-sticky .btn-solid:focus, .navigation.navbar-sticky .btn-solid.focus {
      color: #fff;
      background-color: #ff4c3b;
      border-color: #ff412e;
      box-shadow: 0 0 0 0.2rem rgba(222, 100, 89, 0.5); }
    .navigation .btn-solid.disabled, .navigation .btn-solid:disabled, .navigation.navbar-sticky .btn-solid.disabled, .navigation.navbar-sticky .btn-solid:disabled {
      color: #212529;
      background-color: #ff6f61;
      border-color: #ff6f61; }
    .navigation .btn-solid:not(:disabled):not(.disabled):active, .navigation .btn-solid:not(:disabled):not(.disabled).active,
    .show > .navigation .btn-solid.dropdown-toggle, .navigation.navbar-sticky .btn-solid:not(:disabled):not(.disabled):active, .navigation.navbar-sticky .btn-solid:not(:disabled):not(.disabled).active,
    .show > .navigation.navbar-sticky .btn-solid.dropdown-toggle {
      color: #fff;
      background-color: #ff412e;
      border-color: #ff3521; }
      .navigation .btn-solid:not(:disabled):not(.disabled):active:focus, .navigation .btn-solid:not(:disabled):not(.disabled).active:focus,
      .show > .navigation .btn-solid.dropdown-toggle:focus, .navigation.navbar-sticky .btn-solid:not(:disabled):not(.disabled):active:focus, .navigation.navbar-sticky .btn-solid:not(:disabled):not(.disabled).active:focus,
      .show > .navigation.navbar-sticky .btn-solid.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(222, 100, 89, 0.5); }
    .navigation .btn-solid:hover, .navigation.navbar-sticky .btn-solid:hover {
      box-shadow: 0 5px 10px rgba(255, 111, 97, 0.4) !important; }
  .navigation.navbar-sticky .logo {
    display: none; }
    .navigation.navbar-sticky .logo.logo-sticky {
      display: block !important; }
      .navigation.navbar-sticky .logo.logo-sticky + .logo {
        display: none !important; }
  @media (min-width: 768px) {
    .navigation {
      background-color: #fff;
      padding: 0; }
      .navigation:not(.fixed-top) {
        position: static;
        top: -90px; }
      .navigation.fixed-top {
        background-color: transparent; }
        .navigation.fixed-top .navbar-collapse {
          height: auto; }
        .navigation.fixed-top .nav-link {
          color: #ffeae5; }
          .navigation.fixed-top .nav-link.active, .navigation.fixed-top .nav-link:hover {
            color: #ffd3cb; }
      .navigation.navbar-sticky {
        background-color: #fff;
        top: 0; }
        .navigation.navbar-sticky .nav-link {
          color: #ff6f61; }
          .navigation.navbar-sticky .nav-link.active, .navigation.navbar-sticky .nav-link:hover {
            color: #b14d43; }
      .navigation.navbar-expanded .navbar-toggler,
      .navigation.navbar-expanded .navbar-brand,
      .navigation.navbar-expanded ~ main {
        transform: translateX(0) !important; }
      .navigation.sidebar-left .collapse {
        left: 0;
        border-right: none; }
      .navigation.sidebar-right .collapse {
        right: 0;
        border-left: none; }
      .navigation.sidebar-right .navbar-brand {
        order: 0; }
      .navigation.dark-link .nav-link {
        color: #27333e;
        opacity: 0.7; }
        .navigation.dark-link .nav-link.active, .navigation.dark-link .nav-link:hover {
          opacity: 1;
          color: #181715; }
      .navigation.dark-link .btn-outline {
        color: #ff6f61;
        border-color: #ff6f61; }
        .navigation.dark-link .btn-outline:hover {
          color: #212529;
          background-color: #ff6f61;
          border-color: #ff6f61; }
        .navigation.dark-link .btn-outline:focus, .navigation.dark-link .btn-outline.focus {
          box-shadow: 0 0 0 0.2rem rgba(255, 111, 97, 0.5); }
        .navigation.dark-link .btn-outline.disabled, .navigation.dark-link .btn-outline:disabled {
          color: #ff6f61;
          background-color: transparent; }
        .navigation.dark-link .btn-outline:not(:disabled):not(.disabled):active, .navigation.dark-link .btn-outline:not(:disabled):not(.disabled).active,
        .show > .navigation.dark-link .btn-outline.dropdown-toggle {
          color: #212529;
          background-color: #ff6f61;
          border-color: #ff6f61; }
          .navigation.dark-link .btn-outline:not(:disabled):not(.disabled):active:focus, .navigation.dark-link .btn-outline:not(:disabled):not(.disabled).active:focus,
          .show > .navigation.dark-link .btn-outline.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(255, 111, 97, 0.5); }
      .navigation.dark-link .btn-solid {
        color: #212529;
        background-color: #ff6f61;
        border-color: #ff6f61; }
        .navigation.dark-link .btn-solid:hover {
          color: #fff;
          background-color: #ff4c3b;
          border-color: #ff412e; }
        .navigation.dark-link .btn-solid:focus, .navigation.dark-link .btn-solid.focus {
          color: #fff;
          background-color: #ff4c3b;
          border-color: #ff412e;
          box-shadow: 0 0 0 0.2rem rgba(222, 100, 89, 0.5); }
        .navigation.dark-link .btn-solid.disabled, .navigation.dark-link .btn-solid:disabled {
          color: #212529;
          background-color: #ff6f61;
          border-color: #ff6f61; }
        .navigation.dark-link .btn-solid:not(:disabled):not(.disabled):active, .navigation.dark-link .btn-solid:not(:disabled):not(.disabled).active,
        .show > .navigation.dark-link .btn-solid.dropdown-toggle {
          color: #fff;
          background-color: #ff412e;
          border-color: #ff3521; }
          .navigation.dark-link .btn-solid:not(:disabled):not(.disabled):active:focus, .navigation.dark-link .btn-solid:not(:disabled):not(.disabled).active:focus,
          .show > .navigation.dark-link .btn-solid.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(222, 100, 89, 0.5); }
      .navigation .collapse {
        position: relative;
        width: auto;
        background: transparent; }
      .navigation .nav-link {
        color: #ff6f61;
        padding-left: 1.25rem !important;
        padding-right: 1.25rem !important; }
        .navigation .nav-link i {
          display: none; }
        .navigation .nav-link.active, .navigation .nav-link:hover {
          color: #cb584d; }
      .navigation .dropdown.show .dropdown-toggle {
        color: #ffd3cb; }
      .navigation .sidebar-brand {
        display: none; }
      .navigation .dropdown-menu {
        margin-top: -10px;
        border: 1px solid #edf4f8;
        min-width: 100%;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.075); }
      .navigation.navbar-sticky .dropdown-menu {
        margin-top: -3px; } }

.list-group-no-border .list-group-item {
  border: none; }

/* ==========================================================================
    [6. SECTIONS]
========================================================================== */
/* 6.1 General styles ======================================================= */
section,
.section {
  position: relative; }
  section .container,
  section .container-fluid,
  section .container-sm,
  section .container-md,
  section .container-lg,
  section .container-xl,
  .section .container,
  .section .container-fluid,
  .section .container-sm,
  .section .container-md,
  .section .container-lg,
  .section .container-xl {
    padding-top: 45px;
    padding-bottom: 45px;
    position: relative; }
    section .container.bring-to-front,
    section .container-fluid.bring-to-front,
    section .bring-to-front.container-sm,
    section .bring-to-front.container-md,
    section .bring-to-front.container-lg,
    section .bring-to-front.container-xl,
    .section .container.bring-to-front,
    .section .container-fluid.bring-to-front,
    .section .bring-to-front.container-sm,
    .section .bring-to-front.container-md,
    .section .bring-to-front.container-lg,
    .section .bring-to-front.container-xl {
      z-index: 9; }
    @media (min-width: 768px) {
      section .container,
      section .container-fluid,
      section .container-sm,
      section .container-md,
      section .container-lg,
      section .container-xl,
      .section .container,
      .section .container-fluid,
      .section .container-sm,
      .section .container-md,
      .section .container-lg,
      .section .container-xl {
        padding-top: 90px;
        padding-bottom: 90px; } }
    @media (max-width: 767.98px) {
      section .container,
      section .container-fluid,
      section .container-sm,
      section .container-md,
      section .container-lg,
      section .container-xl,
      .section .container,
      .section .container-fluid,
      .section .container-sm,
      .section .container-md,
      .section .container-lg,
      .section .container-xl {
        overflow: hidden; } }
  section .container-wide,
  .section .container-wide {
    overflow-x: hidden; }
    section .container-wide .row,
    .section .container-wide .row {
      margin-left: 0 !important;
      margin-right: 0 !important; }
  section.shadow,
  .section.shadow {
    z-index: 1; }

.section-heading {
  margin-bottom: 60px;
  position: relative; }
  .section-heading h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 2.875rem; }
  .section-heading p {
    font-weight: 300; }

.heading-line:after {
  content: "";
  display: block;
  margin-top: 32px;
  margin-bottom: 24px;
  width: 40px;
  height: 2px;
  background-color: #ff6f61; }

.text-center .heading-line:after {
  margin-left: auto;
  margin-right: auto; }

@media (min-width: 768px) {
  .partners img {
    opacity: 0.5;
    transform: scale(0.8);
    transition: all 0.3s ease-in-out; }
    .partners img:hover {
      opacity: 1;
      transform: scale(1); } }

.centered-screen {
  position: relative; }
  .centered-screen .screen-highlight {
    bottom: 45px; }
  .centered-screen .message {
    bottom: 130px;
    z-index: 1; }

.bubbles-wrapper {
  position: absolute;
  width: 100%;
  min-height: 200px;
  bottom: 0; }
  .bubbles-wrapper .bubble {
    position: absolute; }
    .bubbles-wrapper .bubble:nth-child(1) {
      animation: floating 5.26667s ease-in-out infinite alternate;
      bottom: 45%;
      left: 22%; }
      @media (min-width: 992px) {
        .bubbles-wrapper .bubble:nth-child(1) {
          animation: bubble-v 15.8s linear infinite, shake-v 2s ease-in-out infinite alternate; } }
    .bubbles-wrapper .bubble.top-initial:nth-child(1) {
      top: 0%; }
    .bubbles-wrapper .bubble:nth-child(2) {
      animation: floating 7.23333s ease-in-out infinite alternate;
      bottom: 40%;
      left: 32%; }
      @media (min-width: 992px) {
        .bubbles-wrapper .bubble:nth-child(2) {
          animation: bubble-v 21.7s linear infinite, shake-v 2s ease-in-out infinite alternate; } }
    .bubbles-wrapper .bubble.top-initial:nth-child(2) {
      top: 10%; }
    .bubbles-wrapper .bubble:nth-child(3) {
      animation: floating 9.6s ease-in-out infinite alternate;
      bottom: 56%;
      left: 44%; }
      @media (min-width: 992px) {
        .bubbles-wrapper .bubble:nth-child(3) {
          animation: bubble-v 28.8s linear infinite, shake-v 2s ease-in-out infinite alternate; } }
    .bubbles-wrapper .bubble.top-initial:nth-child(3) {
      top: 8%; }
    .bubbles-wrapper .bubble:nth-child(4) {
      animation: floating 6.6s ease-in-out infinite alternate;
      bottom: 51%;
      left: 71%; }
      @media (min-width: 992px) {
        .bubbles-wrapper .bubble:nth-child(4) {
          animation: bubble-v 19.8s linear infinite, shake-v 2s ease-in-out infinite alternate; } }
    .bubbles-wrapper .bubble.top-initial:nth-child(4) {
      top: 6%; }
    .bubbles-wrapper .bubble:nth-child(5) {
      animation: floating 6.5s ease-in-out infinite alternate;
      bottom: 65%;
      left: 50%; }
      @media (min-width: 992px) {
        .bubbles-wrapper .bubble:nth-child(5) {
          animation: bubble-v 19.5s linear infinite, shake-v 2s ease-in-out infinite alternate; } }
    .bubbles-wrapper .bubble.top-initial:nth-child(5) {
      top: 50%; }
    .bubbles-wrapper .bubble:nth-child(6) {
      animation: floating 7.56667s ease-in-out infinite alternate;
      bottom: 44%;
      left: 5%; }
      @media (min-width: 992px) {
        .bubbles-wrapper .bubble:nth-child(6) {
          animation: bubble-v 22.7s linear infinite, shake-v 2s ease-in-out infinite alternate; } }
    .bubbles-wrapper .bubble.top-initial:nth-child(6) {
      top: 70%; }
    .bubbles-wrapper .bubble:nth-child(7) {
      animation: floating 9.16667s ease-in-out infinite alternate;
      bottom: 80%;
      left: 70%; }
      @media (min-width: 992px) {
        .bubbles-wrapper .bubble:nth-child(7) {
          animation: bubble-v 27.5s linear infinite, shake-v 2s ease-in-out infinite alternate; } }
    .bubbles-wrapper .bubble.top-initial:nth-child(7) {
      top: 90%; }
    .bubbles-wrapper .bubble:nth-child(8) {
      animation: floating 7.66667s ease-in-out infinite alternate;
      bottom: 55%;
      left: 38%; }
      @media (min-width: 992px) {
        .bubbles-wrapper .bubble:nth-child(8) {
          animation: bubble-v 23s linear infinite, shake-v 2s ease-in-out infinite alternate; } }
    .bubbles-wrapper .bubble.top-initial:nth-child(8) {
      top: 81%; }
    .bubbles-wrapper .bubble:nth-child(9) {
      animation: floating 6.46667s ease-in-out infinite alternate;
      bottom: 50%;
      left: 80%; }
      @media (min-width: 992px) {
        .bubbles-wrapper .bubble:nth-child(9) {
          animation: bubble-v 19.4s linear infinite, shake-v 2s ease-in-out infinite alternate; } }
    .bubbles-wrapper .bubble.top-initial:nth-child(9) {
      top: 51%; }
    .bubbles-wrapper .bubble:nth-child(10) {
      animation: floating 6.06667s ease-in-out infinite alternate;
      bottom: 55%;
      left: 32%; }
      @media (min-width: 992px) {
        .bubbles-wrapper .bubble:nth-child(10) {
          animation: bubble-v 18.2s linear infinite, shake-v 2s ease-in-out infinite alternate; } }
    .bubbles-wrapper .bubble.top-initial:nth-child(10) {
      top: 45%; }
    .bubbles-wrapper .bubble:nth-child(11) {
      animation: floating 5.46667s ease-in-out infinite alternate;
      bottom: 60%;
      left: 4%; }
      @media (min-width: 992px) {
        .bubbles-wrapper .bubble:nth-child(11) {
          animation: bubble-v 16.4s linear infinite, shake-v 2s ease-in-out infinite alternate; } }
    .bubbles-wrapper .bubble.top-initial:nth-child(11) {
      top: 95%; }
    .bubbles-wrapper .bubble:nth-child(12) {
      animation: floating 5.93333s ease-in-out infinite alternate;
      bottom: 60%;
      left: 48%; }
      @media (min-width: 992px) {
        .bubbles-wrapper .bubble:nth-child(12) {
          animation: bubble-v 17.8s linear infinite, shake-v 2s ease-in-out infinite alternate; } }
    .bubbles-wrapper .bubble.top-initial:nth-child(12) {
      top: 92%; }
    .bubbles-wrapper .bubble:nth-child(13) {
      animation: floating 6.4s ease-in-out infinite alternate;
      bottom: 70%;
      left: 50%; }
      @media (min-width: 992px) {
        .bubbles-wrapper .bubble:nth-child(13) {
          animation: bubble-v 19.2s linear infinite, shake-v 2s ease-in-out infinite alternate; } }
    .bubbles-wrapper .bubble.top-initial:nth-child(13) {
      top: 78%; }
    .bubbles-wrapper .bubble:nth-child(14) {
      animation: floating 4.06667s ease-in-out infinite alternate;
      bottom: 75%;
      left: 7%; }
      @media (min-width: 992px) {
        .bubbles-wrapper .bubble:nth-child(14) {
          animation: bubble-v 12.2s linear infinite, shake-v 2s ease-in-out infinite alternate; } }
    .bubbles-wrapper .bubble.top-initial:nth-child(14) {
      top: 6%; }
    .bubbles-wrapper .bubble:nth-child(15) {
      animation: floating 6.6s ease-in-out infinite alternate;
      bottom: 50%;
      left: 90%; }
      @media (min-width: 992px) {
        .bubbles-wrapper .bubble:nth-child(15) {
          animation: bubble-v 19.8s linear infinite, shake-v 2s ease-in-out infinite alternate; } }
    .bubbles-wrapper .bubble.top-initial:nth-child(15) {
      top: 78%; }
    .bubbles-wrapper .bubble:nth-child(16) {
      animation: floating 6.86667s ease-in-out infinite alternate;
      bottom: 68%;
      left: 64%; }
      @media (min-width: 992px) {
        .bubbles-wrapper .bubble:nth-child(16) {
          animation: bubble-v 20.6s linear infinite, shake-v 2s ease-in-out infinite alternate; } }
    .bubbles-wrapper .bubble.top-initial:nth-child(16) {
      top: 45%; }
    .bubbles-wrapper .bubble .badge {
      opacity: 0;
      transform: translateX(-100%);
      transition: all .3s linear;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0; }
    .bubbles-wrapper .bubble:hover .badge {
      opacity: 1;
      transform: translateX(0); }

.integration-bubbles .bubbles-wrapper {
  position: relative;
  height: 100%; }

.integration-bubbles .bubble {
  bottom: 30% !important;
  transform: translateY(-100%);
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (min-width: 768px) {
    .integration-bubbles .bubble {
      bottom: 0 !important; } }

.faqs-dd .card {
  border: 0; }

.faqs-dd .card-header {
  border-radius: 0.25rem;
  background-color: #fff;
  border-bottom: 0; }

.faqs-dd .card-title {
  margin-bottom: 0;
  color: #ff6f61;
  transition: color .3s ease-in; }
  .faqs-dd .card-title.collapsed {
    color: #007cb7; }

.faqs-dd .card-body {
  padding-left: 2rem; }

.faqs-dd .icon {
  left: 0;
  top: 30%;
  font-size: 26rem;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1; }
  .faqs-dd .icon i {
    transform: translate3d(-25%, 0, 0); }

/* 6.3 Footer ======================================================= */
.site-footer {
  font-size: .9em; }
  .site-footer h4 {
    margin-bottom: 15px; }
  .site-footer .logo {
    max-height: 56px; }

.pricing-plans .shape {
  position: absolute;
  box-shadow: -45px 85px 85px 5px rgba(24, 23, 21, 0.03);
  border-radius: 65px;
  transform: rotate(-35deg);
  width: 130px;
  height: 800px; }
  .pricing-plans .shape-1 {
    top: 0;
    left: 0; }
  .pricing-plans .shape-2 {
    top: 0;
    left: 16%; }
  .pricing-plans .shape-3 {
    top: 40%;
    left: 75%; }
  .pricing-plans .shape-4 {
    top: 25%;
    left: 80%; }

.pricing-plans .icon-holder .icon {
  position: absolute;
  color: #edf4f8; }
  .pricing-plans .icon-holder .icon:nth-child(1) {
    top: 15%;
    left: 15%; }
  .pricing-plans .icon-holder .icon:nth-child(2) {
    top: 25%;
    left: 25%; }
  .pricing-plans .icon-holder .icon:nth-child(3) {
    top: 80%;
    left: 4%; }
  .pricing-plans .icon-holder .icon:nth-child(4) {
    top: 85%;
    left: 15%; }
  .pricing-plans .icon-holder .icon:nth-child(5) {
    top: 15%;
    left: 85%; }
  .pricing-plans .icon-holder .icon:nth-child(6) {
    top: 25%;
    left: 75%; }

.pricing-plans .keep-sticky {
  width: 100%;
  left: 0;
  position: fixed;
  top: 90px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  margin: 0 !important; }
  .pricing-plans .keep-sticky .btn {
    width: 50%;
    border-radius: 0; }

.pricing-plan {
  margin-bottom: 1rem;
  border: 1px solid #edf4f8;
  border-radius: 0.25rem;
  background-color: #fff; }
  .pricing-plan p {
    line-height: 1.5em; }
  .pricing-plan .pricing-value {
    font-family: "Lato", sans-serif;
    font-size: 4.5rem; }
    .pricing-plan .pricing-value .price {
      position: relative; }
      .pricing-plan .pricing-value .price:before, .pricing-plan .pricing-value .price:after {
        font-size: 0.35em;
        font-weight: 300;
        font-style: italic; }
      .pricing-plan .pricing-value .price:before {
        position: absolute;
        content: "$";
        top: 10px;
        left: -15px; }
      .pricing-plan .pricing-value .price:after {
        position: relative;
        content: ""; }
      .pricing-plan .pricing-value .price.yearly:after {
        content: "/yr"; }
  @media (min-width: 992px) {
    .pricing-plan {
      margin-bottom: 0;
      /** Remove side borders **/ }
      .pricing-plan:nth-child(1) {
        border-right: none; }
      .pricing-plan:nth-child(3) {
        border-left: none; }
      .pricing-plan.best-value {
        box-shadow: 0 0 45px 0 rgba(24, 23, 21, 0.07); } }

.odometer-inside {
  display: inline; }

.start-free .icon {
  position: absolute;
  color: #edf4f8; }
  .start-free .icon:nth-of-type(1) {
    top: 90%;
    left: 46%; }
  .start-free .icon:nth-of-type(2) {
    top: 14%;
    left: 18%; }
  .start-free .icon:nth-of-type(3) {
    top: 80%;
    left: 4%; }
  .start-free .icon:nth-of-type(4) {
    top: 50%;
    left: 18%; }
  .start-free .icon:nth-of-type(5) {
    top: 25%;
    left: 34%; }
  .start-free .icon:nth-of-type(6) {
    top: 15%;
    left: 72%; }
  .start-free .icon:nth-of-type(7) {
    top: 80%;
    left: 70%; }
  .start-free .icon:nth-of-type(8) {
    top: 55%;
    left: 95%; }
  .start-free .icon:nth-of-type(9) {
    top: 50%;
    left: 80%; }
  .start-free .icon:nth-of-type(10) {
    top: 34%;
    left: 60%; }
  .start-free .icon:nth-of-type(11) {
    top: 60%;
    left: 60%; }
  .start-free .icon:nth-of-type(12) {
    top: 35%;
    left: 75%; }
  .start-free .icon:nth-of-type(13) {
    top: 90%;
    left: 40%; }
  .start-free .icon:nth-of-type(14) {
    top: 54%;
    left: 75%; }
  .start-free .icon:nth-of-type(15) {
    top: 50%;
    left: 90%; }
  .start-free .icon:nth-of-type(16) {
    top: 50%;
    left: 81%; }

@media (min-width: 768px) {
  .why-choose-us .section-heading {
    margin-bottom: 120px; } }

.why-choose-us .section-heading p.lead {
  width: 70%; }

.why-choose-us .browser {
  transform: translate(40%, -10%) rotate3d(0.342, -0.94, 0, 22deg) rotateZ(7deg);
  width: 380px;
  bottom: 0; }
  @media (min-width: 768px) {
    .why-choose-us .browser {
      bottom: auto;
      width: 780px; } }

.why-choose-us .iphone {
  transform: translate(-20%, 10%) rotate3d(0.5, 0.866, 0, 16deg) rotateZ(-7deg); }
  @media (max-width: 767.98px) {
    .why-choose-us .iphone {
      width: 60%;
      margin-right: 0;
      margin-left: 0; } }

.why-choose-us .list-item {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.why-choose-us .icon-shape {
  min-width: 80px; }

.why-choose-us .shape-pipes {
  width: 100px;
  height: 10px;
  background-color: #ffeae5; }
  .why-choose-us .shape-pipes, .why-choose-us .shape-pipes:before, .why-choose-us .shape-pipes:after {
    transform: rotate(-35deg);
    border-radius: 50px; }
  .why-choose-us .shape-pipes:before, .why-choose-us .shape-pipes:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
  .why-choose-us .shape-pipes:before {
    width: 80px;
    height: 7px;
    transform: translate(30px, -8px);
    background-color: #fee9de; }
  .why-choose-us .shape-pipes:after {
    height: 5px;
    width: 60px;
    transform: translate(14px, 10px);
    background-color: #afd4fa; }

.why-choose-us .shapes-container {
  z-index: 2; }

.why-choose-us .pattern {
  position: absolute; }
  .why-choose-us .pattern-dots {
    bottom: 7rem;
    left: 60%;
    width: 50%;
    height: 155px;
    transform: rotate(-10deg);
    background-color: transparent;
    background-image: radial-gradient(#d3d4d5 2px, transparent 2px);
    background-size: 20px 20px; }

.features-cant-miss .bubble {
  z-index: 1;
  position: absolute;
  max-width: 60%; }
  .features-cant-miss .bubble-left {
    top: 18%; }
  .features-cant-miss .bubble-right {
    right: 30px;
    top: 64%; }
  @media (min-width: 768px) {
    .features-cant-miss .bubble {
      max-width: 50%; }
      .features-cant-miss .bubble-left {
        top: 21%; }
      .features-cant-miss .bubble-right {
        right: 60px; } }

.features-cant-miss .shape {
  position: absolute; }
  .features-cant-miss .shape-circle-1 {
    left: 7%;
    top: 60%; }
    .features-cant-miss .shape-circle-1 div {
      width: 150px;
      height: 150px;
      background: #007cb7;
      border-radius: 50%;
      margin: 0; }
    @media (min-width: 768px) {
      .features-cant-miss .shape-circle-1 {
        left: 25%;
        top: 5%; } }
  .features-cant-miss .shape-circle-2 {
    left: 60%;
    top: 70%; }
    .features-cant-miss .shape-circle-2 div {
      width: 120px;
      height: 120px;
      background: rgba(0, 124, 183, 0.8);
      border-radius: 50%;
      margin: 0; }
    @media (min-width: 768px) {
      .features-cant-miss .shape-circle-2 {
        left: 22%;
        top: 23%; } }
  .features-cant-miss .shape-circle-3 {
    left: 40%;
    top: 78%; }
    .features-cant-miss .shape-circle-3 div {
      width: 120px;
      height: 120px;
      background: #007cb7;
      border-radius: 50%;
      margin: 0; }
  .features-cant-miss .shape-circle-4 {
    left: 25%;
    top: 70%; }
    .features-cant-miss .shape-circle-4 div {
      width: 50px;
      height: 50px;
      background: #ff6f61;
      border-radius: 50%;
      margin: 0; }
  .features-cant-miss .shape-triangle {
    top: 80%;
    right: 0; }
    .features-cant-miss .shape-triangle div {
      opacity: .15;
      position: relative;
      background-color: #fc9e21;
      background: linear-gradient(45deg, #fc9e21 0, #ff6f61 100%);
      text-align: left;
      transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
      animation-duration: 50s; }
      .features-cant-miss .shape-triangle div:before, .features-cant-miss .shape-triangle div:after {
        content: '';
        position: absolute;
        background-color: inherit;
        background: inherit; }
      .features-cant-miss .shape-triangle div, .features-cant-miss .shape-triangle div:before, .features-cant-miss .shape-triangle div:after {
        width: 95px;
        height: 95px;
        border-top-right-radius: 30%; }
      .features-cant-miss .shape-triangle div:before {
        transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%); }
      .features-cant-miss .shape-triangle div:after {
        transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%); }
  .features-cant-miss .shape.pattern-dots-1 {
    bottom: 0;
    left: 0;
    width: 150px;
    height: 50%;
    transform: translate3d(0, 50%, 0);
    background-color: transparent;
    background-image: radial-gradient(rgba(39, 51, 62, 0.3) 3px, transparent 3px);
    background-size: 30px 30px; }

.lightweight-template .learn-more {
  margin-bottom: 18rem; }

.isometric-mockups {
  pointer-events: none;
  position: absolute;
  display: flex;
  align-items: center;
  left: 50%;
  margin-left: 85px;
  transform: scale(0.5) rotate(-12deg) translateX(-50%);
  transform-origin: 0 20%;
  z-index: 3;
  top: 340px; }
  .isometric-mockups > div {
    margin: 0 50px; }
  .isometric-mockups .ipad {
    max-width: 100%; }
  .isometric-mockups .phone-big {
    display: none; }
  .isometric-mockups .phone-small {
    order: -1;
    width: 225px; }
  .isometric-mockups .tablet.landscape {
    width: 512px;
    margin: 0; }
  .isometric-mockups .tablet.portrait {
    width: 450px;
    margin-top: 0; }
  @media (min-width: 576px) {
    .isometric-mockups {
      margin-left: 0; } }
  @media (min-width: 768px) {
    .isometric-mockups {
      top: 40px;
      flex-wrap: wrap;
      transform: rotate(-12deg);
      transform-origin: 100% 0;
      width: 829px;
      margin-left: -10px; }
      .isometric-mockups .tablet {
        margin: 0 !important;
        max-width: 100%; }
      .isometric-mockups .lightweight-template .learn-more {
        margin-bottom: 0; } }
  @media (min-width: 768px) {
    .isometric-mockups > div {
      margin: 0 auto; }
    .isometric-mockups .tablet.landscape {
      align-self: flex-end;
      margin-right: 50px;
      transform: translateX(0); }
    .isometric-mockups .tablet.portrait {
      display: flex;
      width: 450px;
      margin-top: 50px !important; }
    .isometric-mockups .phone-big {
      display: flex;
      width: 267px; }
    .isometric-mockups .phone-small {
      order: 0;
      align-self: flex-start;
      margin: 50px 50px 0 0; } }

.powered-design .shapes-container {
  overflow: unset; }

.powered-design .shape {
  position: absolute; }
  .powered-design .shape-circle {
    bottom: 0;
    right: 0; }
    .powered-design .shape-circle-1 {
      transform: translate3d(25%, 10%, 0); }
      .powered-design .shape-circle-1 div {
        width: 750px;
        height: 750px;
        background: rgba(237, 244, 248, 0.5);
        border-radius: 50%;
        margin: 0; }
    .powered-design .shape-circle-2 {
      transform: translate3d(25%, 10%, 0); }
      .powered-design .shape-circle-2 div {
        width: 500px;
        height: 500px;
        background: rgba(237, 244, 248, 0.6);
        border-radius: 50%;
        margin: 0; }
  .powered-design .shape-triangle {
    top: 80%;
    left: 0; }
    .powered-design .shape-triangle div {
      opacity: .15;
      position: relative;
      background-color: #ff6f61;
      background: linear-gradient(45deg, #ff6f61 0, #ffbdb1 100%);
      text-align: left;
      transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
      animation-duration: 50s; }
      .powered-design .shape-triangle div:before, .powered-design .shape-triangle div:after {
        content: '';
        position: absolute;
        background-color: inherit;
        background: inherit; }
      .powered-design .shape-triangle div, .powered-design .shape-triangle div:before, .powered-design .shape-triangle div:after {
        width: 95px;
        height: 95px;
        border-top-right-radius: 30%; }
      .powered-design .shape-triangle div:before {
        transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%); }
      .powered-design .shape-triangle div:after {
        transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%); }
  .powered-design .shape-ring {
    left: 50%;
    top: 0; }
    .powered-design .shape-ring div {
      border-radius: 50%;
      height: 20px;
      width: 20px;
      border: 5px solid #95c2ec; }
  .powered-design .shape.pattern-dots-1 {
    bottom: 0;
    right: 0;
    width: 150px;
    height: 50%;
    transform: translate3d(0, 50%, 0);
    background-color: transparent;
    background-image: radial-gradient(rgba(39, 51, 62, 0.3) 3px, transparent 3px);
    background-size: 30px 30px; }

@media (min-width: 768px) {
  .stay-tunned .form-wrapper {
    width: 50%; } }

.our-proposal .shape, .our-proposal .pattern {
  position: absolute; }

.our-proposal .pattern-dots {
  left: 5%;
  top: 25%;
  width: 220px;
  height: 220px;
  background-color: transparent;
  background-image: radial-gradient(#edf4f8 3px, transparent 3px);
  background-size: 15px 15px; }
  @media (min-width: 768px) {
    .our-proposal .pattern-dots {
      left: 15%;
      top: 5%; } }

.our-proposal .shape-circle {
  width: 120px;
  height: 120px;
  background: #fbd2bc;
  border-radius: 50%;
  margin: 0; }
  .our-proposal .shape-circle.pattern-dots {
    left: 60%;
    top: 0;
    background-color: transparent;
    background-image: radial-gradient(#edf4f8 2px, transparent 2px);
    background-size: 10px 10px; }
  .our-proposal .shape-circle-fill {
    left: 58%;
    top: 8%; }

/* ==========================================================================
    [8. PAGE HEADING]
========================================================================== */
.navigation.fixed-top + main .header {
  padding-top: 90px; }

.header {
  position: relative; }
  .header .container {
    position: relative;
    margin: 0 auto; }

@media (min-width: 768px) {
  .mobile-device[class*='absolute'] {
    margin-top: -45px !important; } }

.alter1-header .container {
  padding-bottom: 10rem; }

.alter1-header:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  width: 300%;
  height: 200%;
  border-radius: 50%;
  transform: translate3d(-50%, -50%, 0);
  background-image: linear-gradient(45deg, #fe2983 0, #ff6f61 50%, #fc9e21 110%); }
  @media (min-width: 768px) {
    .alter1-header:before {
      width: 200%; } }

.alter1-header .shape {
  position: absolute; }
  .alter1-header .shape:nth-child(1) {
    top: 2%;
    left: 11%;
    width: 400px;
    height: 70px; }
  .alter1-header .shape:nth-child(2) {
    top: 14%;
    left: 18%;
    width: 200px;
    height: 15px; }
  .alter1-header .shape:nth-child(3) {
    top: 80%;
    left: 4%;
    width: 300px;
    height: 60px; }
  .alter1-header .shape:nth-child(4) {
    top: 85%;
    left: 15%;
    width: 100px;
    height: 10px; }
  .alter1-header .shape:nth-child(5) {
    top: 5%;
    left: 50%;
    width: 300px;
    height: 25px; }
  .alter1-header .shape:nth-child(6) {
    top: 4%;
    left: 52%;
    width: 200px;
    height: 5px; }
  .alter1-header .shape:nth-child(7) {
    top: 80%;
    left: 70%;
    width: 200px;
    height: 5px; }
  .alter1-header .shape:nth-child(8) {
    top: 55%;
    left: 95%;
    width: 200px;
    height: 5px; }
  .alter1-header .shape:nth-child(9) {
    top: 50%;
    left: 90%;
    width: 300px;
    height: 50px; }
  .alter1-header .shape:nth-child(10) {
    top: 30%;
    left: 60%;
    width: 500px;
    height: 55px; }
  .alter1-header .shape:nth-child(11) {
    top: 60%;
    left: 60%;
    width: 200px;
    height: 5px; }
  .alter1-header .shape:nth-child(12) {
    top: 35%;
    left: 75%;
    width: 200px;
    height: 5px; }
  .alter1-header .shape:nth-child(13) {
    top: 90%;
    left: 40%;
    width: 300px;
    height: 45px; }
  .alter1-header .shape:nth-child(14) {
    top: 54%;
    left: 75%;
    width: 200px;
    height: 5px; }
  .alter1-header .shape:nth-child(15) {
    top: 50%;
    left: 90%;
    width: 200px;
    height: 5px; }
  .alter1-header .shape:nth-child(16) {
    top: 50%;
    left: 81%;
    width: 100px;
    height: 5px; }
  .alter1-header .shape.shape-diagonal:before {
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.1);
    transform: rotate(-35deg);
    position: absolute;
    border-radius: 50px; }

.alter2-header .head-line-2 {
  display: block; }
  @media (min-width: 768px) {
    .alter2-header .head-line-2 {
      display: inline; } }

.alter2-header .background-shape-main {
  bottom: 0;
  width: 100%;
  height: 100%;
  transform: translate(85%, -30%) rotate(-35deg);
  background-image: linear-gradient(45deg, #fe2983 0, #ff6f61 35%, #fc9e21 110%); }
  @media (min-width: 768px) {
    .alter2-header .background-shape-main {
      height: 115%;
      transform: translate(35%, -30%) rotate(-35deg); } }
  @media (min-width: 992px) {
    .alter2-header .background-shape-main {
      height: 130%; } }
  @media (min-width: 1200px) {
    .alter2-header .background-shape-main {
      height: 190%; } }

.alter2-header .animation-shape {
  position: absolute;
  display: flex; }
  .alter2-header .animation-shape.shape-ring {
    top: 75%;
    left: 25%; }
    .alter2-header .animation-shape.shape-ring:after {
      content: "";
      border-radius: 50%;
      height: 20px;
      width: 20px;
      border: 5px solid #ff6f61; }
  .alter2-header .animation-shape.shape-circle-1 {
    top: 60%;
    left: 30%; }
    .alter2-header .animation-shape.shape-circle-1:after {
      content: "";
      width: 5px;
      height: 5px;
      background: rgba(0, 124, 183, 0.3);
      border-radius: 50%;
      margin: 0; }
  .alter2-header .animation-shape.shape-circle-2 {
    top: 30%;
    left: 75%;
    animation-duration: 15s; }
    .alter2-header .animation-shape.shape-circle-2:after {
      content: "";
      width: 15px;
      height: 15px;
      background: rgba(237, 244, 248, 0.3);
      border-radius: 50%;
      margin: 0; }
  .alter2-header .animation-shape.shape-triangle {
    top: 50%;
    left: 50%;
    animation-duration: 12s; }
    .alter2-header .animation-shape.shape-triangle div {
      opacity: 0.1;
      position: relative;
      background-color: #ff6f61;
      text-align: left;
      transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866); }
      .alter2-header .animation-shape.shape-triangle div:before, .alter2-header .animation-shape.shape-triangle div:after {
        content: '';
        position: absolute;
        background-color: inherit; }
      .alter2-header .animation-shape.shape-triangle div, .alter2-header .animation-shape.shape-triangle div:before, .alter2-header .animation-shape.shape-triangle div:after {
        width: 30px;
        height: 30px;
        border-top-right-radius: 30%; }
      .alter2-header .animation-shape.shape-triangle div:before {
        transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%); }
      .alter2-header .animation-shape.shape-triangle div:after {
        transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%); }
  .alter2-header .animation-shape.shape-diamond {
    top: 35%;
    left: 55%; }
    .alter2-header .animation-shape.shape-diamond div {
      opacity: 0.5;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom-color: #007cb7;
      position: relative;
      top: -10px; }
      .alter2-header .animation-shape.shape-diamond div:after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        left: -10px;
        top: 10px;
        border: 10px solid transparent;
        border-top-color: #007cb7; }
  .alter2-header .animation-shape.shape-heart {
    top: 35%;
    left: 15%;
    animation-duration: 15s; }
    .alter2-header .animation-shape.shape-heart div {
      opacity: 0.5;
      position: relative;
      width: 30px;
      height: 25px; }
      .alter2-header .animation-shape.shape-heart div:before, .alter2-header .animation-shape.shape-heart div:after {
        position: absolute;
        content: "";
        top: 0;
        left: 15px;
        width: 15px;
        height: 25px;
        background: #007cb7;
        border-radius: 15px 15px 0 0;
        transform: rotate(-45deg);
        transform-origin: 0 100%; }
      .alter2-header .animation-shape.shape-heart div:after {
        left: 0;
        transform: rotate(45deg);
        transform-origin: 100% 100%; }

.alter2-header .static-shape {
  position: absolute; }
  .alter2-header .static-shape.shape-circle {
    top: 0;
    right: 0;
    opacity: 0.2; }
    .alter2-header .static-shape.shape-circle-1 {
      transform: translate3d(25%, -25%, 0); }
      .alter2-header .static-shape.shape-circle-1 div {
        width: 500px;
        height: 500px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        margin: 0; }
    .alter2-header .static-shape.shape-circle-2 {
      transform: translate3d(25%, -25%, 0); }
      .alter2-header .static-shape.shape-circle-2 div {
        width: 300px;
        height: 300px;
        background: rgba(255, 255, 255, 0.25);
        border-radius: 50%;
        margin: 0; }
  .alter2-header .static-shape.shape-ring-1 {
    border-radius: 50%;
    height: 80px;
    width: 80px;
    border: 15px solid #007cb7;
    top: 35%;
    left: 0;
    transform: translateX(-50%); }
  .alter2-header .static-shape.shape-ring-2 {
    border-radius: 50%;
    height: 120px;
    width: 120px;
    border: 15px solid rgba(255, 255, 255, 0.5);
    top: 20%;
    right: 0;
    transform: translateX(50%); }
  .alter2-header .static-shape.pattern-dots-1 {
    top: 0;
    left: 0;
    width: 150px;
    height: 20%;
    background-color: transparent;
    background-image: radial-gradient(rgba(0, 0, 0, 0.1) 3px, transparent 3px);
    background-size: 30px 30px; }

.alter2-header .shape {
  position: absolute; }
  .alter2-header .shape:nth-child(1) {
    top: 0%;
    left: 0%;
    width: 600px;
    height: 40px; }
    .alter2-header .shape:nth-child(1).shape-diagonal:before {
      background-image: linear-gradient(45deg, #fe2983 0, #ff6f61 35%, #fc9e21 110%); }
  .alter2-header .shape:nth-child(2) {
    top: 20%;
    left: 12%;
    width: 200px;
    height: 10px; }
    .alter2-header .shape:nth-child(2).shape-diagonal:before {
      background-image: linear-gradient(90deg, #ff6f61 0, #fc9e21 100%); }
  .alter2-header .shape:nth-child(3) {
    top: 80%;
    left: 45%;
    width: 200px;
    height: 5px; }
    .alter2-header .shape:nth-child(3).shape-diagonal:before {
      background-image: linear-gradient(90deg, #d3d4d5 0, #edf4f8 100%); }
  .alter2-header .shape:nth-child(4) {
    top: 82%;
    left: 45%;
    width: 150px;
    height: 15px; }
    .alter2-header .shape:nth-child(4).shape-diagonal:before {
      background-image: linear-gradient(90deg, #d3d4d5 0, #edf4f8 100%); }
  .alter2-header .shape:nth-child(5) {
    top: 20%;
    left: 0%;
    width: 200px;
    height: 20px; }
    .alter2-header .shape:nth-child(5).shape-diagonal:before {
      opacity: 0.5; }
    .alter2-header .shape:nth-child(5).shape-diagonal:before {
      background-image: linear-gradient(90deg, #edf4f8 0, #d3d4d5 100%); }
  .alter2-header .shape:nth-child(6) {
    top: 34%;
    left: 5%;
    width: 60px;
    height: 6px; }
    .alter2-header .shape:nth-child(6).shape-diagonal:before {
      opacity: 0.5; }
    .alter2-header .shape:nth-child(6).shape-diagonal:before {
      background-image: linear-gradient(90deg, #ff6f61 0, #fc9e21 100%); }
  .alter2-header .shape:nth-child(7) {
    top: 17%;
    left: 3%;
    width: 100px;
    height: 6px; }
    .alter2-header .shape:nth-child(7).shape-diagonal:before {
      opacity: 0.5; }
    .alter2-header .shape:nth-child(7).shape-diagonal:before {
      background-image: linear-gradient(90deg, #d3d4d5 0, #d3d4d5 100%); }
  .alter2-header .shape:nth-child(8) {
    top: 70%;
    left: -5%;
    width: 300px;
    height: 5px; }
    .alter2-header .shape:nth-child(8).shape-diagonal:before {
      opacity: 0.5; }
    .alter2-header .shape:nth-child(8).shape-diagonal:before {
      background-image: linear-gradient(90deg, #ff6f61 0, #fc9e21 100%); }
  .alter2-header .shape:nth-child(9) {
    top: 72%;
    left: -5%;
    width: 200px;
    height: 15px; }
    .alter2-header .shape:nth-child(9).shape-diagonal:before {
      opacity: 0.5; }
    .alter2-header .shape:nth-child(9).shape-diagonal:before {
      background-color: #fc9e21; }
  .alter2-header .shape:nth-child(10) {
    top: 0%;
    left: 85%;
    width: 300px;
    height: 25px; }
    .alter2-header .shape:nth-child(10).shape-diagonal:before {
      background-color: rgba(255, 255, 255, 0.1); }
  .alter2-header .shape:nth-child(11) {
    top: 3%;
    left: 91%;
    width: 150px;
    height: 10px; }
    .alter2-header .shape:nth-child(11).shape-diagonal:before {
      background-color: rgba(255, 255, 255, 0.1); }
  @media (min-width: 768px) {
    .alter2-header .shape:nth-child(1) {
      top: 25%; }
    .alter2-header .shape:nth-child(2) {
      top: 20%; } }
  @media (min-width: 992px) {
    .alter2-header .shape:nth-child(1) {
      left: 12%; }
    .alter2-header .shape:nth-child(2) {
      top: 10%;
      width: 300px; } }
  @media (min-width: 1200px) {
    .alter2-header .shape:nth-child(1) {
      left: 31%; }
    .alter2-header .shape:nth-child(2) {
      left: 31%; } }
  .alter2-header .shape.shape-diagonal:before {
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    transform: rotate(55deg);
    position: absolute;
    border-radius: 50px; }

.alter3-header .container {
  padding-bottom: 20rem; }
  @media (min-width: 768px) {
    .alter3-header .container {
      padding-bottom: 15rem; } }

.alter3-header .animation-shape {
  position: absolute; }
  .alter3-header .animation-shape.shape-triangle {
    top: 50%;
    left: 50%; }
    .alter3-header .animation-shape.shape-triangle div {
      opacity: .1;
      position: relative;
      background-color: #fff;
      text-align: left;
      transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866); }
      .alter3-header .animation-shape.shape-triangle div:before, .alter3-header .animation-shape.shape-triangle div:after {
        content: '';
        position: absolute;
        background-color: inherit; }
      .alter3-header .animation-shape.shape-triangle div, .alter3-header .animation-shape.shape-triangle div:before, .alter3-header .animation-shape.shape-triangle div:after {
        width: 15px;
        height: 15px;
        border-top-right-radius: 30%; }
      .alter3-header .animation-shape.shape-triangle div:before {
        transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%); }
      .alter3-header .animation-shape.shape-triangle div:after {
        transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%); }
  .alter3-header .animation-shape.shape-ring {
    top: 75%;
    left: 25%; }
    .alter3-header .animation-shape.shape-ring div {
      border-radius: 50%;
      height: 20px;
      width: 20px;
      border: 5px solid #ff6f61; }
  .alter3-header .animation-shape.shape-circle {
    top: 20%;
    left: 50%; }
    .alter3-header .animation-shape.shape-circle div {
      width: 15px;
      height: 15px;
      background: rgba(237, 244, 248, 0.3);
      border-radius: 50%;
      margin: 0; }
  .alter3-header .animation-shape.shape-diamond {
    top: 35%;
    left: 25%; }
    .alter3-header .animation-shape.shape-diamond div {
      opacity: .5;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom-color: #007cb7;
      position: relative;
      top: -10px; }
      .alter3-header .animation-shape.shape-diamond div:after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        left: -10px;
        top: 10px;
        border: 10px solid transparent;
        border-top-color: #007cb7; }

.alter3-header .static-shape {
  position: absolute; }
  .alter3-header .static-shape.shape-ring-1 {
    border-radius: 50%;
    height: 80px;
    width: 80px;
    border: 15px solid rgba(255, 255, 255, 0.5);
    top: 15%;
    left: 0;
    transform: translateX(-50%); }
  .alter3-header .static-shape.shape-ring-2 {
    border-radius: 50%;
    height: 120px;
    width: 120px;
    border: 15px solid rgba(255, 255, 255, 0.5);
    top: 5%;
    right: 0;
    transform: translateX(50%); }
  .alter3-header .static-shape.shape-circle-1 {
    width: 120px;
    height: 120px;
    background: rgba(255, 255, 255, 0.09);
    border-radius: 50%;
    margin: 0;
    top: 22%;
    left: 22%; }
  .alter3-header .static-shape.pattern-dots-1 {
    top: 0;
    right: 0;
    width: 250px;
    height: 50%;
    background-color: transparent;
    background-image: radial-gradient(rgba(255, 255, 255, 0.1) 3px, transparent 3px);
    background-size: 30px 30px; }
  .alter3-header .static-shape.pattern-dots-2 {
    left: 0;
    bottom: 0;
    width: 150px;
    height: 20%;
    background-color: transparent;
    background-image: radial-gradient(rgba(255, 255, 255, 0.1) 3px, transparent 3px);
    background-size: 30px 30px; }
  .alter3-header .static-shape.ghost-shape {
    opacity: .3;
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 120px; }
    .alter3-header .static-shape.ghost-shape-1 {
      top: 0;
      transform: translate3D(30%, -93%, 0) skewX(35deg) rotate(-12deg);
      background-color: #e56357;
      max-height: 560px;
      box-shadow: none; }

.alter3-features .shapes-container {
  overflow: unset; }

@media (min-width: 992px) {
  .alter3-features .rotated-cards {
    transform: rotate(-5deg); } }

.alter3-features .shape {
  position: absolute; }
  .alter3-features .shape-ring {
    right: 0;
    top: 0;
    transform: translate3d(50%, -50%, 0); }
    .alter3-features .shape-ring-1 .animation {
      border-radius: 50%;
      height: 680px;
      width: 680px;
      border: 5px solid #edf4f8;
      animation-duration: 45s; }
    .alter3-features .shape-ring-2 .animation {
      border-radius: 50%;
      height: 560px;
      width: 560px;
      border: 1px solid #d3d4d5;
      animation-duration: 60s; }
    .alter3-features .shape-ring .animation {
      border-style: dashed; }
  .alter3-features .shape-circle {
    top: 50px;
    right: 120px; }
    .alter3-features .shape-circle div {
      width: 15px;
      height: 15px;
      background: #5f9ed2;
      border-radius: 50%;
      margin: 0; }

.alter3-features .background-shape-main {
  z-index: 0;
  left: 0;
  top: 10%;
  width: 500px;
  height: 720px;
  transform: translate3d(-25%, 20%, 0) rotate(-30deg);
  background-image: linear-gradient(35deg, #fff 0, #edf4f8 100%); }
  @media (min-width: 768px) {
    .alter3-features .background-shape-main {
      width: 1200px;
      top: 0; } }

/* ==========================================================================
    [9. CUSTOM]
========================================================================== */
p {
  margin: 1rem 0; }

.logo {
  max-width: 180px; }

.swiper-container .swiper-pagination-top {
  bottom: auto;
  top: 0; }

hr[class*='bg'] {
  border-top: 0;
  height: 1px; }

.off-left-background {
  background-position: -60px 20px;
  transform: translate3d(0, 0, 0);
  transition: all .4s ease; }
  .off-left-background:hover {
    background-position: -60px 0px;
    transform: translate3d(0, -10px, 0); }

.nav-process {
  min-height: 60px; }

hr {
  border-top: 1px solid #edf4f8; }
